import { Listbox } from '@headlessui/react'

interface DropdownProps {
    options: string[]
    selected: string
    setSelected: React.Dispatch<React.SetStateAction<string>>
    placeholder: string
}

const Dropdown: React.FC<DropdownProps> = ({
    options,
    selected,
    setSelected,
    placeholder,
}) => {
    return (
        <div className="relative w-60">
            <Listbox value={selected} onChange={setSelected}>
                <Listbox.Button className="w-full rounded-[12px] border border-borderGrey bg-white px-4 py-2 text-left text-textGrey200 shadow-sm focus:border-[2px] focus:border-textGrey100 focus:outline-none ">
                    {selected === '' ? placeholder : selected}
                </Listbox.Button>
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-[12px] bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                    {options.map((option, idx) => (
                        <Listbox.Option
                            key={idx}
                            className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active
                                        ? 'bg-bgRed text-textGrey200'
                                        : 'text-textGrey200'
                                }`
                            }
                            value={option}
                        >
                            {({ selected }) => (
                                <>
                                    <span
                                        className={`${selected ? 'font-medium' : 'font-normal'} block truncate text-textGrey200`}
                                    >
                                        {option}
                                    </span>
                                    {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                            {/* Icon for selected item */}
                                        </span>
                                    ) : null}
                                </>
                            )}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </Listbox>
        </div>
    )
}

export default Dropdown
