import React, { useState } from 'react'
import { DocumentData, DocumentReference, updateDoc } from 'firebase/firestore'
import SettingsRow from '../../../components/SettingsRow'
import ProfileTextInput from '../../../components/ProfileTextInput'

interface IntegrationsProps {
    profile: any
    profileRef: DocumentReference<DocumentData>
}

const Integrations: React.FC<IntegrationsProps> = ({ profile, profileRef }) => {
    const [clioGrowToken, setClioGrowToken] = useState<string>(
        profile.clio_inbox_token
    )
    const [leadDocketToken, setLeadDocketToken] = useState<string>(
        profile.lead_docket_endpoint
    )
    return (
        <div>
            <SettingsRow
                title="Clio Grow"
                description="Send all new leads to your Clio Grow inbox. To set this up, find your Clio Grow Inbox Token and save it below. In Clio Grow, go to Settings > Integrations. Scroll down to Clio Grow inbox and copy and paste below your Clio Inbox Token."
                value={clioGrowToken}
                onSave={() => {
                    updateDoc(profileRef, {
                        clio_inbox_token: clioGrowToken,
                    })
                }}
            >
                <ProfileTextInput
                    className="mr-3 min-w-[290px]"
                    title="Clio Grow Inbox Token"
                    value={clioGrowToken}
                    setter={setClioGrowToken}
                />
            </SettingsRow>
            <SettingsRow
                title="Lead Docket"
                description="Send all new leads to your Lead Docket. To set this up, copy and paste the opportunity form endpoint below. It will look like this https://[FIRMNAME].leaddocket.com/Opportunities/Form/[FORM_NUM]"
                value={leadDocketToken}
                onSave={() => {
                    updateDoc(profileRef, {
                        lead_docket_endpoint: leadDocketToken,
                    })
                }}
            >
                <ProfileTextInput
                    className="mr-3 min-w-[290px]"
                    title="Lead Docket Endpoint URL"
                    value={leadDocketToken}
                    setter={setLeadDocketToken}
                />
            </SettingsRow>
        </div>
    )
}

export default Integrations
