import React, { useState } from 'react'
import { DocumentData, DocumentReference, updateDoc } from 'firebase/firestore'

import SettingsRow from '../../../components/SettingsRow'
import ProfileTextInput from '../../../components/ProfileTextInput'
import {
    convert_to_e164,
    parsePhoneToReadable,
} from '../../../utils/phoneParsing'
import { Switch } from '@headlessui/react'
import ProfilePhoneInput from '../../../components/ProfilePhoneInput'
import ButtonClear from '../../../components/ButtonClear'

interface NotificationsProps {
    profile: any
    profileRef: DocumentReference<DocumentData>
}

const Notifications: React.FC<NotificationsProps> = ({
    profile,
    profileRef,
}) => {
    const [textPhone, setTextPhone] = useState(profile.notifications.text)
    const [email, setEmail] = useState(profile.notifications.email || [])
    const [receiveEmailNotifs, setReceiveEmailNotifs] = useState(
        profile.notifications.receive_email
    )
    const [receiveTextNotifs, setReceiveTextNotifs] = useState(
        profile.notifications.receive_text
    )

    const updateReceiveEmailNotifs = async () => {
        updateDoc(profileRef, {
            'notifications.receive_email': !receiveEmailNotifs,
        })
        setReceiveEmailNotifs(!receiveEmailNotifs)
    }
    const updateReceiveTextNotifs = async () => {
        updateDoc(profileRef, {
            'notifications.receive_text': !receiveTextNotifs,
        })
        setReceiveTextNotifs(!receiveTextNotifs)
    }
    const updateTextPhone = async () => {
        await updateDoc(profileRef, {
            'notifications.text': '+' + textPhone.replace(/\D/g, ''),
        })
    }

    const updateEmail = async () => {
        await updateDoc(profileRef, {
            'notifications.email': email,
        })
    }
    return (
        <div>
            <div className=" border-b-[1px] border-borderGrey py-6">
                <div className="flex justify-between">
                    <div className="">
                        <p className="font-semibold">Text Notifications</p>
                    </div>
                    <Switch
                        checked={receiveTextNotifs}
                        onChange={updateReceiveTextNotifs}
                        className={`${receiveTextNotifs ? 'bg-Red' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                        <span
                            className={`${receiveTextNotifs ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white`}
                        />
                    </Switch>
                </div>
            </div>
            <SettingsRow
                title="Text Message Number"
                description="This is the phone number you will receive text notifications to when a new lead arrives."
                value={parsePhoneToReadable(textPhone)}
                onSave={updateTextPhone}
            >
                <ProfilePhoneInput
                    className="mr-16 w-[310px]"
                    title="Phone"
                    value={parsePhoneToReadable(textPhone)}
                    setter={(value: string) => {
                        setTextPhone(convert_to_e164(value))
                    }}
                />
            </SettingsRow>
            <div className=" border-b-[1px] border-borderGrey py-6">
                <div className="flex justify-between">
                    <div className="">
                        <p className="font-semibold">Email Notifications</p>
                    </div>
                    <Switch
                        checked={receiveEmailNotifs}
                        onChange={updateReceiveEmailNotifs}
                        className={`${receiveEmailNotifs ? 'bg-Red' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                        <span
                            className={`${receiveEmailNotifs ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white`}
                        />
                    </Switch>
                </div>
            </div>
            <SettingsRow
                title="Email Address"
                description="This is the email address you will receive email notifications to when a new lead arrives."
                value={email.join(', ')}
                onSave={updateEmail}
            >
                <div className="mb-10">
                    {email.map((e: any, index: any) => (
                        <div key={index} className="mb-2 flex items-center">
                            <ProfileTextInput
                                className="mr-16 w-[310px]"
                                title={`Email ${index + 1}`}
                                value={e}
                                setter={(value: string) => {
                                    const updatedEmail = [...email]
                                    updatedEmail[index] = value
                                    setEmail(updatedEmail)
                                }}
                            />
                            <button
                                className=" mt-4 text-Red"
                                onClick={() => {
                                    const updatedEmail = email.filter(
                                        (_: any, i: any) => i !== index
                                    )
                                    setEmail(updatedEmail)
                                }}
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                    <ButtonClear
                        title="Add Another Email"
                        className=" mt-5 border-[2px] border-Navy p-0 px-9 py-[14px]"
                        textBlack={true}
                        onClick={() => setEmail([...email, ''])}
                    />
                </div>
            </SettingsRow>
        </div>
    )
}

export default Notifications
