import { useState } from 'react'

import Modal from '../../../components/Modal'
import Loading from '../../../components/Loading'
import ButtonClear from '../../../components/ButtonClear'
import Button from '../../../components/Button'
import GarbageIcon from '../../../assets/images/GarbageIcon.svg'
import NoteIcon from '../../../assets/images/NoteIcon.svg'
import GarbageBig from '../../../assets/images/GarbageBig.svg'

interface DeleteNoteModalProps {
    index: number
    handleDelete: (index: number) => void
}

const DeleteNoteModal = ({ handleDelete, index }: DeleteNoteModalProps) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        setLoading(true)
        await handleDelete(index)
        setModalOpen(false)
        setLoading(false)
    }
    return (
        <div className="h-8 w-8 min-w-8">
            <img
                src={GarbageIcon}
                onClick={() => {
                    setModalOpen(!modalOpen)
                }}
                className="ml-6 h-[17px] w-[16px]"
                alt="garbage icon"
            />
            <Modal visible={modalOpen}>
                <div className="w-[500px]">
                    <div className="mb-2 flex rounded-t-[16px] bg-bgGrey p-6">
                        <img src={NoteIcon} className="h-5" alt="note icon" />
                        <span className="ml-2 text-[24px] font-semibold text-Navy">
                            Update Note for Lead
                        </span>
                    </div>
                    <div className="rounded-b-[16px] px-6 pb-6">
                        <div className="mx-auto mt-8 flex h-[120px] w-[120px] items-center justify-center rounded-full bg-bgRed">
                            <img
                                src={GarbageBig}
                                className="w-14 "
                                alt="garbage icon"
                            />
                        </div>
                        <p className="base24 mb-2 text-center font-semibold">
                            Are your sure?
                        </p>
                        <p className="text-testGrey200 mb-8 text-center">
                            This will delete this note permanently.
                        </p>
                        {loading ? (
                            <div className="mt-8 flex h-full w-full items-center justify-center">
                                <Loading />
                            </div>
                        ) : (
                            <div className="mt-8 flex justify-center">
                                <ButtonClear
                                    title="Keep it!"
                                    onClick={() => {
                                        setModalOpen(!modalOpen)
                                    }}
                                    className="w-[200px] bg-bgGrey px-9 py-[18px] text-textGrey200"
                                />
                                <Button
                                    title="Yes, Delete it!"
                                    className="ml-6 w-[200px]  px-9 py-[18px]"
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default DeleteNoteModal
