import { lead_type_map_readable } from './leadMaps'

export const parsePracticeAreas = (areas: string[]) => {
    let practice_areas_readable = ''
    for (let i = 0; i < areas.length; i++) {
        if (i === areas.length - 1) {
            practice_areas_readable += lead_type_map_readable[areas[i]]
        } else {
            practice_areas_readable += lead_type_map_readable[areas[i]] + ', '
        }
    }
    return practice_areas_readable
}

export const parseStringArray = (arr: string[]) => {
    let arr_readable = ''

    for (let i = 0; i < arr.length; i++) {
        if (i === arr.length - 1) {
            arr_readable += arr[i]
        } else {
            arr_readable += arr[i] + ', '
        }
    }

    return arr_readable
}

export function parseJurisdiction(jurisdiction: {
    [key: string]: { entire_state: boolean; counties: string[] }
}) {
    const states = Object.keys(jurisdiction)

    return parseStringArray(states)
}
