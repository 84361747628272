import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import NavMenu from '../NavMenu'

function BaseLayout() {
    const [navOpen, setNavOpen] = useState(false)
    return (
        <>
            <NavMenu isOpen={navOpen} setIsOpen={setNavOpen} />
            <div
                className={`transition-all duration-300 ease-in-out ${navOpen ? 'ml-64' : 'ml-24'}`}
            >
                <Outlet />
            </div>
        </>
    )
}

export default BaseLayout
