import React, { createContext, useState, useContext, ReactNode } from 'react'

export type UserDataType = {
    id: string
    name: string
    email: string
    role: string
    picture: string
    configured: boolean
} | null

type UserContextType = {
    userData: UserDataType
    setUserData: React.Dispatch<React.SetStateAction<UserDataType>>
}

const UserContext = createContext<UserContextType | null>(null)

type UserProviderProps = {
    children: ReactNode
}

export const UserProvider = ({ children }: UserProviderProps) => {
    const [userData, setUserData] = useState<UserDataType>(null)
    return (
        <UserContext.Provider value={{ userData, setUserData }}>
            {children}
        </UserContext.Provider>
    )
}

export const useUser = () => useContext(UserContext) as UserContextType
