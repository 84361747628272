import React from 'react'
import ButtonClear from '../ButtonClear'
import Button from '../Button'
import Tooltip from '../Tooltip'
import InfoIcon from '../../assets/images/InfoIcon.svg'

interface SettingsRowProps {
    title: string
    value: string | number
    description?: string
    onSave?: () => void
    children?: React.ReactNode
    noEdit?: boolean
    tooltipText?: string
    subSetting?: boolean
}

const SettingsRow: React.FC<SettingsRowProps> = ({
    title,
    value,
    description,
    onSave,
    children,
    noEdit,
    tooltipText,
    subSetting,
}) => {
    const [edit, setEdit] = React.useState(false)
    return (
        <div className=" border-b-[1px] border-borderGrey py-6">
            {edit ? (
                <div>
                    <div className="flex justify-between">
                        <div className="">
                            <div className="flex">
                                <p className="font-semibold">{title}</p>
                                {tooltipText && (
                                    <Tooltip text={tooltipText}>
                                        <img
                                            src={InfoIcon}
                                            className="ml-2"
                                            alt="info icon"
                                        />
                                    </Tooltip>
                                )}
                            </div>

                            <p className="">{description}</p>
                        </div>
                        <ButtonClear
                            title="Cancel"
                            className="ml-6 !items-start p-0 underline"
                            onClick={() => {
                                setEdit(false)
                            }}
                        />
                    </div>
                    <div className="mt-8">{children}</div>
                    <Button
                        title="Save"
                        className=" mt-5 px-9 py-[14px]"
                        onClick={() => {
                            if (onSave) onSave()
                            setEdit(false)
                        }}
                    />
                </div>
            ) : (
                <div className="flex justify-between">
                    <div className="">
                        <div className="flex">
                            <p className="font-semibold">{title}</p>
                            {tooltipText && (
                                <Tooltip text={tooltipText}>
                                    <img
                                        src={InfoIcon}
                                        className="ml-2"
                                        alt="info icon"
                                    />
                                </Tooltip>
                            )}
                        </div>
                        <p>{value}</p>
                    </div>
                    {!noEdit && (
                        <ButtonClear
                            title="Edit"
                            className="ml-6 !items-start p-0 underline"
                            onClick={() => {
                                setEdit(true)
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default SettingsRow
