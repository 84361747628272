import { useEffect, useState } from 'react'
import {
    onSnapshot,
    query,
    collection,
    where,
    orderBy,
} from 'firebase/firestore'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import ContentArea from '../../components/ContentArea'
import { db } from '../../config'
import { useUser } from '../../components/UserContext'
import { useFirestoreListener } from '../../components/FirestoreListenerContext'
import TD from '../../components/TableData'
import TH from '../../components/TableHeader'
import TR from '../../components/TableRow'
import DocIcon from '../../assets/images/DocIcon.svg'
import { formatCurrency } from '../../utils/leadMaps'

function Invoices() {
    const [invoices, setInvoices] = useState([] as any[])

    const { userData } = useUser()
    const { addUnsubscribeFunction } = useFirestoreListener()
    const navigate = useNavigate()

    useEffect(() => {
        function handleSearch() {
            try {
                let invoicesQuery

                if (userData?.role === 'admin') {
                    invoicesQuery = query(
                        collection(db, 'invoices'),
                        orderBy('issue_date', 'desc')
                    )
                } else {
                    invoicesQuery = query(
                        collection(db, 'invoices'),
                        where('lawyer_id', '==', userData!.id + ''),
                        orderBy('issue_date', 'desc')
                    )
                }

                const unsubscribe = onSnapshot(invoicesQuery, (snapshot) => {
                    setInvoices(
                        snapshot.docs.map((invoice) => ({
                            id: invoice.id,
                            ...invoice.data(),
                        }))
                    )
                })
                addUnsubscribeFunction(unsubscribe)
            } catch (error) {
                console.error('error searching invoices:', error)
            }
        }

        handleSearch()
    }, [])

    return (
        <ContentArea>
            <h1 className="mb-8">Invoices</h1>
            <div className="rounded-2xl bg-white">
                <table className="w-full">
                    <TR className="base16  text-left text-Navy">
                        <TH className="pl-6">Number</TH>
                        <TH>Issue Date</TH>
                        <TH>Amount</TH>
                        <TH>Status</TH>
                        <TH>PDF</TH>
                    </TR>
                    {invoices.length > 1 &&
                        invoices.map((invoice: any) => {
                            return (
                                <tr
                                    className="cursor-pointer"
                                    key={invoice.id}
                                    onClick={() => {
                                        navigate(`/invoices/${invoice.id}`)
                                    }}
                                >
                                    <TD className="pl-6 text-Red">
                                        {invoice.xero_invoice_number.substring(
                                            0,
                                            10
                                        )}
                                    </TD>
                                    <TD className="">
                                        {moment(
                                            invoice.issue_date.toDate()
                                        ).format('MM/DD/YYYY')}
                                    </TD>
                                    <TD>{formatCurrency(invoice.total)}</TD>
                                    <TD className="capitalize">
                                        <div
                                            className={
                                                'base14 h-full w-24 rounded-xl px-4 py-1 text-center font-bold' +
                                                (invoice.status === 'paid'
                                                    ? ' bg-bgGreen text-Green'
                                                    : invoice.status ===
                                                        'unpaid'
                                                      ? ' bg-bgRed text-Red'
                                                      : ' bg-bgSecondColor text-SecondColor')
                                            }
                                        >
                                            {invoice.status}
                                        </div>
                                    </TD>

                                    <TD>
                                        <div className="flex">
                                            <a
                                                href={invoice.invoice_url}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src={DocIcon}
                                                    className="ml-3 h-4"
                                                    alt="doc icon"
                                                />
                                            </a>
                                        </div>
                                    </TD>
                                </tr>
                            )
                        })}
                </table>
            </div>
        </ContentArea>
    )
}

export default Invoices
