import { Link, useNavigate } from 'react-router-dom'
import { signOut } from 'firebase/auth'

import { ReactComponent as LeadsIcon } from '../../assets/images/LeadsIcon.svg'
import { ReactComponent as BillingIcon } from '../../assets/images/BillingIcon.svg'
import { ReactComponent as InvoiceIcon } from '../../assets/images/InvoiceIcon.svg'
import { ReactComponent as SupportIcon } from '../../assets/images/SupportIcon.svg'
import { ReactComponent as LogoutIcon } from '../../assets/images/LogoutIcon.svg'
import ArrowLeftIcon from '../../assets/images/ArrowLeftIcon.svg'
import ArrowRightIcon from '../../assets/images/ArrowRightIcon.svg'
import Lion from '../../assets/images/Lion.svg'
import LogoText from '../../assets/images/LogoText.svg'
import NavRow from './NavRow'
import NavRowProfile from './NavRowProfile'
import { auth } from '../../config'
import { useUser } from '../../components/UserContext'
import { useEffect, useState } from 'react'
import { useFirestoreListener } from '../../components/FirestoreListenerContext'
import DefaultProfilePicture from '../../assets/images/DefaultProfilePicture.svg'

interface navMenuProps {
    isOpen: boolean
    setIsOpen: (value: boolean) => void
}

function NavMenu({ isOpen, setIsOpen }: navMenuProps) {
    const [showLogoText, setShowLogoText] = useState(false)

    const navigate = useNavigate()
    const { userData, setUserData } = useUser()
    const { clearUnsubscribeFunctions } = useFirestoreListener()

    const handleLogout = async () => {
        try {
            await signOut(auth)
            setUserData(null)
            clearUnsubscribeFunctions()
            navigate('/login')
        } catch (error) {
            console.error('Error signing out: ', error)
        }
    }

    useEffect(() => {
        let timer: NodeJS.Timer
        if (isOpen) {
            timer = setTimeout(() => {
                setShowLogoText(true)
            }, 150)
        } else {
            setShowLogoText(false)
        }

        return () => clearTimeout(timer) // Cleanup the timer on component unmount
    }, [isOpen])

    return (
        <div
            className={`fixed left-0 top-0 flex h-full  w-64 origin-left transform flex-col justify-between overflow-auto bg-Navy transition-all duration-300 ease-in-out ${isOpen ? ' ' : ' scale-x-[.365]'}`}
        >
            <div>
                <Link to="/">
                    <div className="mb-16 flex pt-8">
                        <div>
                            <img
                                className={`h-11 max-w-full origin-left pl-5 transition-all duration-300 ease-in-out ${isOpen ? ' ' : '  scale-x-[2.7397260274]'}`}
                                src={Lion}
                                alt="logo lion icon"
                            />
                        </div>
                        {showLogoText && (
                            <div className={` items- flex  pl-5 `}>
                                <img
                                    className="max-h-full w-auto"
                                    src={LogoText}
                                    alt="logo text"
                                />
                            </div>
                        )}
                    </div>
                </Link>
                <nav className=" flex flex-col gap-5">
                    <NavRow
                        open={isOpen}
                        to="/leads"
                        title="Leads"
                        icon={<LeadsIcon className="h-full w-full" />}
                    />
                    <NavRow
                        open={isOpen}
                        to="/invoices"
                        title="Invoices"
                        icon={<InvoiceIcon className="h-full w-full" />}
                    />
                    {userData?.role === 'client' && (
                        <NavRow
                            open={isOpen}
                            to="/billing"
                            title="Billing"
                            icon={<BillingIcon className="h-full w-full" />}
                        />
                    )}
                </nav>
            </div>
            <div className={`flex justify-end `}>
                {isOpen ? (
                    <img
                        src={ArrowLeftIcon}
                        className="h-8  w-8"
                        onClick={() => {
                            setIsOpen(!isOpen)
                        }}
                        alt="left arrow icon"
                    />
                ) : (
                    <img
                        src={ArrowRightIcon}
                        className="h-10 w-10 scale-x-[2.7397260274] pr-6 transition-all duration-300 ease-in-out"
                        onClick={() => {
                            setIsOpen(!isOpen)
                        }}
                        alt="right arrow icon"
                    />
                )}
            </div>
            <div className="">
                <nav className=" flex flex-col gap-5 pb-6">
                    {/* <NavRow
                        open={isOpen}
                        to="/settings"
                        title="Settings"
                        img={SettingsIcon}
                    /> */}
                    <NavRow
                        open={isOpen}
                        to="/support"
                        title="Support"
                        lowerRow={true}
                        icon={<SupportIcon className="h-full w-full" />}
                    />
                    <NavRow
                        open={isOpen}
                        to="/login"
                        title="Logout"
                        icon={<LogoutIcon className="h-full w-full" />}
                        lowerRow={true}
                        onClick={() => {
                            handleLogout()
                        }}
                    />
                    {/* <NavRow
                        open={isOpen}
                        to="/apps"
                        title="Apps"
                        img={AppsIcon}
                    /> */}
                </nav>
                <div className=" cursor-pointer  border-borderGrey ">
                    <NavRowProfile
                        to={'/profile/' + userData!.id}
                        title={userData!.name}
                        open={isOpen}
                        img={
                            userData && userData.picture !== ''
                                ? userData.picture
                                : DefaultProfilePicture
                            // 'https://a0.muscache.com/im/pictures/user/c1569b4e-8b69-4425-9ddf-67a15df6f5c3.jpg?aki_policy=profile_x_medium'
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default NavMenu
