import React from 'react'
import ButtonClear from '../ButtonClear'
import Tooltip from '../Tooltip'
import InfoIcon from '../../assets/images/InfoIcon.svg'
import Button from '../Button'

interface SettingsGroupRowProps {
    title: string
    value: string | number
    isSubSettingEditing: boolean
    isAddingSetting: boolean
    newSettingButtonText: string
    setIsAddingSetting: (value: boolean) => void
    description?: string
    onSave?: () => void
    children?: React.ReactNode
    noEdit?: boolean
    tooltipText?: string
}

const SettingsGroupRow: React.FC<SettingsGroupRowProps> = ({
    title,
    value,
    description,
    newSettingButtonText,
    onSave,
    children,
    noEdit,
    tooltipText,
    isSubSettingEditing,
    isAddingSetting,
    setIsAddingSetting,
}) => {
    const [edit, setEdit] = React.useState(false)
    return (
        <div className=" border-b-[1px] border-borderGrey py-6">
            {edit ? (
                <div>
                    <div className="flex justify-between">
                        <div className="">
                            <div className="flex">
                                <p className="font-semibold">{title}</p>
                                {tooltipText && (
                                    <Tooltip text={tooltipText}>
                                        <img
                                            src={InfoIcon}
                                            className="ml-2"
                                            alt="info icon"
                                        />
                                    </Tooltip>
                                )}
                            </div>
                            <p>{description}</p>
                        </div>
                        <ButtonClear
                            title="Close"
                            className="ml-6 !items-start  underline"
                            onClick={() => {
                                setEdit(false)
                                setIsAddingSetting(false)
                            }}
                        />
                    </div>
                    <div className="mt-3">{children}</div>
                    {!isSubSettingEditing && !isAddingSetting && (
                        <ButtonClear
                            title={newSettingButtonText}
                            className=" mt-5 border-[2px] border-Red p-0 px-9 py-[14px]"
                            onClick={() => {
                                setEdit(true)
                                setIsAddingSetting(true)
                            }}
                        />
                    )}
                    {!isSubSettingEditing && isAddingSetting && (
                        <Button
                            title={newSettingButtonText}
                            className=" mt-5 p-0 px-9 py-[14px]"
                            onClick={() => {
                                if (onSave) onSave()
                                setEdit(false)
                                setIsAddingSetting(false)
                            }}
                        />
                    )}
                </div>
            ) : (
                <div className="flex justify-between">
                    <div className="">
                        <div className="flex">
                            <p className="font-semibold">{title}</p>
                            {tooltipText && (
                                <Tooltip text={tooltipText}>
                                    <img
                                        src={InfoIcon}
                                        className="ml-2"
                                        alt="info icon"
                                    />
                                </Tooltip>
                            )}
                        </div>
                        <p>{value}</p>
                    </div>
                    {!noEdit && (
                        <ButtonClear
                            title="Edit"
                            className="ml-6 !items-start p-0 underline"
                            onClick={() => {
                                setEdit(true)
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default SettingsGroupRow
