import React, { useState } from 'react'
import { DocumentData, DocumentReference, updateDoc } from 'firebase/firestore'

type BankOptionItem = {
    id: string
    name: string
    mask: string
}
type CardOptionItem = {
    id: string
    type: string
    last4: string
    exp_month: number
    exp_year: number
}

interface UpdateBillingMethodProps {
    bankOptions: BankOptionItem[]
    cardOption: CardOptionItem
    billingRef: DocumentReference<DocumentData, DocumentData>
    defaultValue: string
}

const UpdateBillingMethod: React.FC<UpdateBillingMethodProps> = ({
    bankOptions,
    cardOption,
    billingRef,
    defaultValue,
}) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue)

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedValue(event.target.value)
        const selectedIndex = event.target.selectedIndex
        const label = event.target.options[selectedIndex].text

        updateDoc(billingRef, {
            charge_account: label,
            charge_account_id: event.target.value,
        })
    }

    return (
        <select
            value={selectedValue}
            onChange={handleChange}
            className=" custom-arrow mt-3 w-[350px] rounded-[12px] border-[1px] border-borderGrey px-4 py-[14px] focus:border-[2px] focus:border-textGrey100 focus:outline-none"
        >
            {defaultValue === '' && (
                <option value={defaultValue} disabled>
                    N/A
                </option>
            )}
            {bankOptions.length > 0 &&
                bankOptions.map((option, index) => (
                    <option key={index} value={option.id}>
                        {option.name + ' (' + option.mask + ')'}
                    </option>
                ))}
            {cardOption.last4 !== '' && (
                <option key={cardOption.id} value={cardOption.id}>
                    {'Card (' + cardOption.last4 + ')'}
                </option>
            )}
        </select>
    )
}

export default UpdateBillingMethod
