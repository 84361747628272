import React from 'react'

interface RadioButtonProps {
    isChecked: boolean
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox = ({ isChecked, handleChange }: RadioButtonProps) => {
    return (
        <input
            className="cursor-pointer "
            type="checkbox"
            checked={isChecked}
            onChange={handleChange}
        />
    )
}

export default Checkbox
