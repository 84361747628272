import React, { ReactNode } from 'react'

interface TDProps {
    children?: ReactNode
    className?: string
    colSpan?: number
}

const TD: React.FC<TDProps> = ({ children, className, colSpan }) => {
    return (
        <td
            className={
                className +
                '  border-b-[1px] border-t-[1px] border-borderGrey py-3'
            }
            colSpan={colSpan}
        >
            {children}
        </td>
    )
}

export default TD
