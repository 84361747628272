import React, { useEffect, useState } from 'react'
import ContentArea from '../../components/ContentArea'
import ContentDetail from '../../components/ContentDetail'
import {
    DocumentData,
    DocumentReference,
    doc,
    onSnapshot,
} from 'firebase/firestore'

import { useUser } from '../../components/UserContext'
import { useFirestoreListener } from '../../components/FirestoreListenerContext'
import { db } from '../../config'
import Loading from '../../components/Loading'
import Account from './PersonalInfo'
import PublicProfile from './PublicProfile'
import Notifications from './Notifications'
import Integrations from './Intergrations'

const Profile: React.FC = () => {
    const [profile, setProfile] = useState<null | any>(null)

    const [profileRef, setProfileRef] =
        useState<null | DocumentReference<DocumentData>>(null)

    const { userData } = useUser()
    const { addUnsubscribeFunction } = useFirestoreListener()

    useEffect(() => {
        function handleSearch() {
            try {
                const docRef = doc(db, 'lawyers', userData!.id)
                setProfileRef(docRef)
                const unsubscribe = onSnapshot(docRef, (snapshot) => {
                    setProfile({
                        id: snapshot.id,
                        ...snapshot.data(),
                    })
                })

                addUnsubscribeFunction(unsubscribe)
            } catch (error) {
                console.error('error searching profile:', error)
            }
        }

        handleSearch()
    }, [])

    if (!profile) {
        return (
            <div className="flex h-screen items-center justify-center">
                <Loading />
            </div>
        )
    }

    return (
        <ContentArea>
            <div className="mx-auto max-w-[640px]">
                <h1 className="mb-8">Account</h1>
                <ContentDetail className="mb-8">
                    <span className="base24 mb-8 font-semibold text-Navy">
                        Personal Info
                    </span>
                    <p className="base16 mb-4 text-textGrey200">
                        This information is required, and will be shown publicly
                        on our websites, unless overridden by a Public Profile
                        value.
                    </p>
                    <Account profile={profile} profileRef={profileRef!} />
                </ContentDetail>
                <ContentDetail className="mb-8">
                    <span className="base24 pb-8 font-semibold text-Navy">
                        Public Profile
                    </span>
                    <p className="base16 text-textGrey200">
                        This information is optional. The fields you complete
                        will be used to add personalized details about your
                        Organization in various places on our websites. Some
                        fields, such as Contact Name/Email/Phone, will override
                        the value from your Personal Info in cases where it
                        would be shown publicly.
                    </p>
                    <PublicProfile profile={profile} profileRef={profileRef!} />
                </ContentDetail>
                <ContentDetail className="mb-8">
                    <span className="base24 pb-8 font-semibold text-Navy">
                        Notifications
                    </span>
                    <p className="base16 mb-4 text-textGrey200">
                        This information is optional. This information is used
                        to send you notifications when a new lead arrives.
                    </p>
                    <Notifications profile={profile} profileRef={profileRef!} />
                </ContentDetail>
                <ContentDetail className="mb-8">
                    <span className="base24 pb-8 font-semibold text-Navy">
                        Integrations
                    </span>
                    <p className="base16 mb-4 text-textGrey200">
                        Set up connecting your apps.
                    </p>
                    <Integrations profile={profile} profileRef={profileRef!} />
                </ContentDetail>
            </div>
        </ContentArea>
    )
}

export default Profile
