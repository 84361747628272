import React from 'react'
import ContentArea from '../../components/ContentArea'
import ContentDetail from '../../components/ContentDetail'
import { Link } from 'react-router-dom'

function Support() {
    return (
        <ContentArea>
            <h1 className="mb-8">Support</h1>

            <ContentDetail>
                <div className="space-between flex">
                    <div className=" w-[250px]">
                        <p className="base24">Help</p>
                        <ul>
                            {/* <li className="text-Red">Lexicata</li>
                            <li className="text-Red">Clio</li>
                            <li className="text-Red">Clio Grow</li>
                            <li className="text-Red">Lawmatics</li> */}
                            <li className="text-Red">
                                Bank Draft Payment Option
                            </li>
                            {/* <li className="text-Red">Pro Plan Benefits</li> */}
                        </ul>
                    </div>
                    <div className="ml-6 w-[250px]">
                        <p className="base24">Support Resources</p>
                        <ul>
                            <li className="">
                                Get help via{' '}
                                <a
                                    href="https://www.firmleads.io/support"
                                    target="_blank" rel="noreferrer"
                                >
                                    <span className="text-Red">Email</span>
                                </a>
                            </li>
                            {/* <li className="text-Red">Lexicata</li> */}
                        </ul>
                    </div>
                    <div className="ml-6 w-[250px]">
                        <p className="base24">Support Tools</p>
                        <ul>
                            <Link to="/credits">
                                <li className="text-Red">Credit Requests</li>
                            </Link>
                        </ul>
                    </div>
                </div>
            </ContentDetail>
        </ContentArea>
    )
}

export default Support
