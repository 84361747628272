import React, { useState } from 'react'

interface PhoneInputProps {
    value: string
    setValue: (value: any) => void
    placeholder?: string
    onBlur?: () => void
    className?: string
}

const PhoneInput: React.FC<PhoneInputProps> = ({
    value,
    setValue,
    placeholder,
    onBlur,
    className,
}) => {
    const formatPhoneNumber = (input: string) => {
        // Remove all non-digit characters
        const digits = input.replace(/\D/g, '')

        // Handle numbers starting with 1 followed by 10 digits
        const startsWithOne = digits.startsWith('1') && digits.length <= 11
        const adjustedDigits = startsWithOne ? digits.slice(1) : digits

        let formatted = digits

        // Adjust the formatting logic based on the new criteria
        if (digits.length === 0) {
            formatted = ''
        } else if (digits.length <= 3) {
            formatted = startsWithOne
                ? `+1 (${adjustedDigits}`
                : `(${adjustedDigits}`
        } else if (digits.length <= 6) {
            formatted = startsWithOne
                ? `+1 (${adjustedDigits.slice(0, 3)}) ${adjustedDigits.slice(3)}`
                : `(${adjustedDigits.slice(0, 3)}) ${adjustedDigits.slice(3)}`
        } else if (adjustedDigits.length <= 10) {
            formatted = startsWithOne
                ? `+1 (${adjustedDigits.slice(0, 3)}) ${adjustedDigits.slice(3, 6)}-${adjustedDigits.slice(6)}`
                : `(${adjustedDigits.slice(0, 3)}) ${adjustedDigits.slice(3, 6)}-${adjustedDigits.slice(6)}`
        }

        // Revert to unformatted digits if exceeding expected length, considering the leading '1'
        if (digits.length > (startsWithOne ? 11 : 10)) {
            formatted = digits
        }

        // Return an empty string when no digits are present, allowing the placeholder to show
        return formatted.length === 0 ? '' : formatted
    }
    const [displayValue, setDisplayValue] = useState(
        value ? formatPhoneNumber(value) : ''
    )
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target && e.target.value.length > displayValue.length) {
            const input = e.target.value
            const rawInput = input.replace(/\D/g, '') // Remove non-digit characters
            setValue(rawInput) // Update the actual value
            setDisplayValue(formatPhoneNumber(rawInput)) // Update the display value with formatted input
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace') {
            const newActualValue = value.slice(0, -1) // Remove the last character from the actual value
            setValue(newActualValue) // Update the actual value
            setDisplayValue(formatPhoneNumber(newActualValue)) // Reformat and update the display value
        }
    }

    return (
        <input
            type="tel"
            value={displayValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onBlur={onBlur}
            placeholder={placeholder ?? '(123) 456-7890'}
            className={`${className} `}
        />
    )
}

export default PhoneInput
