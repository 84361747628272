import React, { useState } from 'react'
import PracticeStateSingleSelector from '../../PracticeStateSingleSelector'
import { Switch } from '@headlessui/react'
import ButtonClear from '../../ButtonClear'
import CountySuggest from '../../CountySuggest'

interface AddJurisdictionProps {
    jurisdiction: {
        [key: string]: { entire_state: boolean; counties: string[] }
    }
    setJurisdiction: React.Dispatch<
        React.SetStateAction<{
            [key: string]: { entire_state: boolean; counties: string[] }
        }>
    >
    setIsAddingSetting: (value: boolean) => void
}

const AddJurisdiction: React.FC<AddJurisdictionProps> = ({
    jurisdiction,
    setJurisdiction,
    setIsAddingSetting,
}) => {
    const [selectedState, setSelectedState] = useState<string>('')
    const [entireState, setEntireState] = useState(false)
    const [selectedCounties, setSelectedCounties] = useState<string[]>([])

    function handleSetSelectedState(state: string) {
        if (Object.keys(jurisdiction).includes(state)) {
            setSelectedState('')
            return
        }
        setSelectedState(state)
    }

    function handleSetEntireState(entireState: boolean) {
        let updatedJurisdiction = { ...jurisdiction }
        updatedJurisdiction[selectedState] = {
            entire_state: entireState,
            counties: [],
        }
        setJurisdiction(updatedJurisdiction)
        setEntireState(entireState)
    }

    function handleSetSelectedCounties(counties: string[]) {
        setSelectedCounties(counties)

        let updatedJurisdiction = { ...jurisdiction }
        updatedJurisdiction[selectedState] = {
            entire_state: entireState,
            counties,
        }
        setJurisdiction(updatedJurisdiction)
    }

    console.log('in add jurisdiction', selectedState)

    return (
        <div className="mt-5 ">
            <div className="flex justify-between">
                <p className="pb-2 font-semibold">Add Jurisdiction</p>
                <ButtonClear
                    title="Cancel"
                    className="ml-6 !items-start underline"
                    onClick={() => {
                        setIsAddingSetting(false)
                    }}
                />
            </div>
            <PracticeStateSingleSelector
                displayState={selectedState}
                selectedState={selectedState}
                setSelectedState={handleSetSelectedState}
            />

            {selectedState !== '' && (
                <div className="flex justify-between pb-6">
                    <p className="font-semibold">
                        Do you service the entire state?
                    </p>

                    <Switch
                        checked={entireState}
                        onChange={handleSetEntireState}
                        className={`${entireState ? 'bg-Red' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                        <span
                            className={`${entireState ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white`}
                        />
                    </Switch>
                </div>
            )}

            {selectedState !== '' && !entireState && (
                <div>
                    <p className="pb-2 font-semibold">Select Counties</p>
                    <CountySuggest
                        state={selectedState}
                        selectedCounties={selectedCounties}
                        clearOnStateChange={true}
                        setSelectedCounties={handleSetSelectedCounties}
                    />
                </div>
            )}
        </div>
    )
}

export default AddJurisdiction
