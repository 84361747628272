interface ButtonProps {
    className: string
    title: string
    img?: string
    disabled?: boolean
    onClick?: () => any
}

function Button({ title, className, onClick, img, disabled }: ButtonProps) {
    return (
        <button
            className={
                'flex items-center justify-center rounded-xl border-0 bg-Red  text-center text-base font-semibold capitalize text-white ' +
                className
            }
            onClick={onClick}
            disabled={disabled}
        >
            {img && <img src={img} className="mr-2" alt="icon" />} {title}
        </button>
    )
}

export default Button
