import { useCallback, useState } from 'react'
import { usePlaidLink, PlaidLinkOnSuccessMetadata } from 'react-plaid-link'
import { httpsCallable } from 'firebase/functions'

import { functions } from '../../../config'
import { useUser } from '../../../components/UserContext'
import Button from '../../../components/Button'
import Loading from '../../../components/Loading'

interface UpdateBillingMethodProps {
    linkToken: string | null
    buttonText: string
}

function UpdateBank({ linkToken, buttonText }: UpdateBillingMethodProps) {
    const [updateBankLoading, setUpdateBankLoading] = useState(false)
    const { userData } = useUser()

    const public_access_token = httpsCallable(
        functions,
        'payments-public_token_exchange'
    )

    const onSuccess = useCallback(
        async (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
            try {
                setUpdateBankLoading(true)
                const account_id = metadata.accounts[0].id
                await public_access_token({
                    public_token: public_token,
                    lawyer_id: userData!.id,
                    account_id,
                })
            } catch (error) {
                console.error(error)
                setUpdateBankLoading(false)
            } finally {
                setUpdateBankLoading(false)
            }
        },
        []
    )

    const config: Parameters<typeof usePlaidLink>[0] = {
        token: linkToken!,
        onSuccess,
    }
    const { open, ready } = usePlaidLink(config)

    return (
        <>
            {updateBankLoading ? (
                <Loading />
            ) : (
                <Button
                    onClick={() => {
                        public_access_token({ warm_up: true })
                        open()
                    }}
                    disabled={!ready}
                    className="mt-6 px-9 py-3"
                    title={buttonText}
                />
            )}
        </>
    )
}

export default UpdateBank
