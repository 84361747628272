import React, { createContext, useContext, useState, ReactNode } from 'react'

type FirestoreListenerContextValue = {
    addUnsubscribeFunction: (unsubscribe: () => void) => void
    clearUnsubscribeFunctions: () => void
}

const FirestoreListenerContext =
    createContext<FirestoreListenerContextValue | null>(null)

interface FirestoreListenerProviderProps {
    children: ReactNode
}

export const FirestoreListenerProvider = ({
    children,
}: FirestoreListenerProviderProps) => {
    const [unsubscribeFunctions, setUnsubscribeFunctions] = useState<
        (() => void)[]
    >([])

    const addUnsubscribeFunction = (unsubscribe: () => void) => {
        setUnsubscribeFunctions((prev) => [...prev, unsubscribe])
    }

    const clearUnsubscribeFunctions = () => {
        unsubscribeFunctions.forEach((unsub) => unsub())
        setUnsubscribeFunctions([])
    }

    return (
        <FirestoreListenerContext.Provider
            value={{ addUnsubscribeFunction, clearUnsubscribeFunctions }}
        >
            {children}
        </FirestoreListenerContext.Provider>
    )
}

export const useFirestoreListener = () => {
    const context = useContext(FirestoreListenerContext)
    if (!context) {
        throw new Error(
            'useFirestoreListener must be used within a FirestoreListenerProvider'
        )
    }
    return context
}
