import { useEffect, useState } from 'react'
import {
    onSnapshot,
    updateDoc,
    serverTimestamp,
    doc,
    DocumentReference,
    DocumentData,
} from 'firebase/firestore'
import moment from 'moment'
import { useParams, Link } from 'react-router-dom'
import { httpsCallable } from 'firebase/functions'

import ContentArea from '../../components/ContentArea'
import ContentDetail from '../../components/ContentDetail'
import CreditIcon from '../../assets/images/CreditIcon.svg'
import { db, functions } from '../../config'
import { useFirestoreListener } from '../../components/FirestoreListenerContext'
import Loading from '../../components/Loading'
import BackArrow from '../../assets/images/BackArrow.svg'
import { useUser } from '../../components/UserContext'
import { formatTimestamp } from '../../utils/phoneParsing'

function CreditRequestDetail() {
    const [credit, setCredit] = useState<null | any>(null)
    const [creditRef, setCreditRef] =
        useState<DocumentReference<DocumentData> | null>(null)

    const { addUnsubscribeFunction } = useFirestoreListener()
    const { credit_id } = useParams()
    const { userData } = useUser()
    const update_credits = httpsCallable(functions, 'accounting-update_credits')

    useEffect(() => {
        async function handleSearch() {
            try {
                const docRef = doc(db, 'credits', credit_id!)

                const unsubscribe = await onSnapshot(docRef, (snapshot) => {
                    setCredit({
                        id: snapshot.id,
                        ...snapshot.data(),
                    })
                })
                // setStatus(credit.status)
                addUnsubscribeFunction(unsubscribe)
                setCreditRef(docRef)
            } catch (error) {
                console.error('error searching credits:', error)
            }
        }

        handleSearch()
    }, [credit_id])

    const handleEditStatus = async (status: string) => {
        setCredit({
            ...credit,
            status: status,
        })
        try {
            await updateDoc(creditRef!, {
                status: status,
                updated: serverTimestamp(),
                staff_id: userData!.id,
            })
        } catch (error) {
            console.error('Error updating Firestore document:', error)
        }

        try {
            update_credits({
                lawyer_id: credit.lawyer_id,
                lead_id: credit.lead_id,
                lead_created: credit.lead_created,
                status,
            })
        } catch (error) {
            console.error('Error updating Firestore document:', error)
        }
    }

    if (!credit || !creditRef) {
        return (
            <div className="flex h-screen items-center justify-center">
                <Loading />
            </div>
        )
    }
    return (
        <ContentArea>
            <div className="flex ">
                <Link to="/credits" className="mr-6 mt-2 cursor-pointer">
                    <img src={BackArrow} alt="back arrow" />
                </Link>
                <h1 className="mb-8">{credit.lead_name}</h1>
            </div>
            <ContentDetail>
                <div className="mb-8 flex justify-between">
                    <div className="flex">
                        <img
                            src={CreditIcon}
                            className="h-5"
                            alt="credit icon"
                        />
                        <span className="ml-2 text-[24px] font-semibold text-Navy">
                            Credit Request
                        </span>
                    </div>
                    <div>
                        <span
                            className={
                                'base14 h-full w-32 rounded-xl px-4 py-1 text-center font-bold capitalize ' +
                                (credit.status === 'approved'
                                    ? ' bg-bgGreen text-Green'
                                    : credit.status === 'not_approved'
                                      ? ' bg-bgGrey text-textGrey200'
                                      : 'bg-bgSecondColor text-SecondColor')
                            }
                        >
                            {credit.status.replace('_', ' ')}
                        </span>
                    </div>
                </div>
                <table className="w-full text-Navy">
                    <tr className="base16 text-left text-textGrey200">
                        <th className="pb-2 font-normal">ID</th>
                        <th className="pb-2 font-normal">Lead</th>
                        <th className="pb-2 font-normal">Lead Age (Days)</th>
                        <th className="pb-2 font-normal">Reason</th>
                    </tr>
                    <tr className="base16">
                        <td>{credit.id}</td>
                        <td className="capitalize">
                            {credit.lead_name + ', ' + credit.lead_id}
                        </td>
                        <td>
                            {moment().diff(
                                moment(credit.lead_created.toDate()),
                                'days'
                            )}
                        </td>
                        <td>{credit.reason}</td>
                    </tr>
                    <tr className="base16  text-left text-textGrey200">
                        <th className="pb-2 pt-12 font-normal">Created</th>
                        <th className="pb-2 pt-12 font-normal">Updated</th>
                        <th colSpan={2} className="pb-2 pt-12 font-normal">
                            Detail
                        </th>
                    </tr>
                    <tr>
                        <td>
                            {moment(credit.created.toDate()).format(
                                'MM/DD/YYYY hh:mm a'
                            )}
                        </td>
                        <td>{formatTimestamp(credit.updated)}</td>
                        <td colSpan={5} className="font-semibold">
                            {credit.detail}
                        </td>
                    </tr>
                    {userData!.role === 'admin' && (
                        <tr className="base16  text-left text-textGrey200">
                            <th className="pb-2 pt-12 font-normal">
                                Charge Client
                            </th>
                        </tr>
                    )}
                    {userData!.role === 'admin' && (
                        <tr>
                            <td>
                                <div className=" flex flex-col">
                                    <label className="mr-5">
                                        <input
                                            type="radio"
                                            value="pending"
                                            checked={
                                                credit.status === 'pending'
                                            }
                                            onChange={(e) => {
                                                handleEditStatus(e.target.value)
                                            }}
                                            className="mr-2"
                                        />
                                        Pending
                                    </label>

                                    <label>
                                        <input
                                            type="radio"
                                            value="not_approved"
                                            checked={
                                                credit.status === 'not_approved'
                                            }
                                            onChange={(e) => {
                                                handleEditStatus(e.target.value)
                                            }}
                                            className="mr-2"
                                        />
                                        Not Approved
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value="approved"
                                            checked={
                                                credit.status === 'approved'
                                            }
                                            onChange={(e) => {
                                                handleEditStatus(e.target.value)
                                            }}
                                            className="mr-2"
                                        />
                                        Approved
                                    </label>
                                </div>
                            </td>
                        </tr>
                    )}
                </table>
            </ContentDetail>
        </ContentArea>
    )
}

export default CreditRequestDetail
