import React, { ReactNode } from 'react'

interface TooltipProps {
    children: ReactNode
    text: string
}

const Tooltip: React.FC<TooltipProps> = ({ children, text }) => {
    return (
        <div className="group relative flex flex-col items-center">
            {children}
            <div className="absolute bottom-0 mb-6 hidden flex-col items-center group-hover:flex">
                <span className="relative z-10 whitespace-nowrap rounded-md bg-bgGrey p-2 text-xs leading-none text-Navy shadow-lg">
                    {text}
                </span>
                <div className="-mt-2 h-3 w-3 rotate-45 bg-bgGrey"></div>
            </div>
        </div>
    )
}

export default Tooltip
