import React from 'react'
import ButtonClear from '../ButtonClear'
import Button from '../Button'
import Tooltip from '../Tooltip'
import InfoIcon from '../../assets/images/InfoIcon.svg'

interface SettingsGroupSubSettingProps {
    title: string
    value: string | number
    setSubSettingEditing: (value: boolean) => void
    description?: string
    validate?: () => boolean
    onRemove?: () => void
    onSave?: () => void
    children?: React.ReactNode
    noEdit?: boolean
    tooltipText?: string
}

const SettingsGroupSubSetting: React.FC<SettingsGroupSubSettingProps> = ({
    title,
    value,
    description,
    validate,
    onSave,
    onRemove,
    children,
    noEdit,
    tooltipText,
    setSubSettingEditing,
}) => {
    const [edit, setEdit] = React.useState(false)
    return (
        <div className={noEdit && !edit ? 'py-4' : 'py-2'}>
            {edit ? (
                <div>
                    <div className="flex justify-between">
                        <div className="">
                            <div className="flex">
                                <p className="font-semibold">{title}</p>
                                {tooltipText && (
                                    <Tooltip text={tooltipText}>
                                        <img
                                            src={InfoIcon}
                                            className="ml-2"
                                            alt="info icon"
                                        />
                                    </Tooltip>
                                )}
                            </div>
                            <p>{description}</p>
                        </div>
                        <ButtonClear
                            title="Cancel"
                            className="ml-6 !items-start p-0 underline"
                            onClick={() => {
                                setEdit(false)
                                setSubSettingEditing(false)
                            }}
                        />
                    </div>
                    <div className="mx-4 mt-4">{children}</div>
                    <div className={onRemove ? 'flex justify-between' : ''}>
                        <Button
                            title="Save"
                            className=" ml-4 mt-5 px-9 py-[14px]"
                            onClick={() => {
                                let validated = true
                                if (validate) validated = validate()
                                if (!validated) return
                                if (onSave) onSave()
                                setEdit(false)
                                setSubSettingEditing(false)
                            }}
                        />
                        {onRemove && (
                            <ButtonClear
                                title="Remove"
                                className=" ml-4 mt-5 py-[14px] pr-4 text-Navy underline"
                                onClick={() => {
                                    if (onRemove) onRemove()
                                    setEdit(false)
                                    setSubSettingEditing(false)
                                }}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <div className="flex justify-between">
                    <div className="">
                        <div className="flex">
                            <p className="font-semibold">{title}</p>
                            {tooltipText && (
                                <Tooltip text={tooltipText}>
                                    <img
                                        src={InfoIcon}
                                        className="ml-2"
                                        alt="info icon"
                                    />
                                </Tooltip>
                            )}
                        </div>
                        <p>{value}</p>
                    </div>
                    {!noEdit && (
                        <ButtonClear
                            title="Edit"
                            className="ml-6 !items-start p-0 underline"
                            onClick={() => {
                                setEdit(true)
                                setSubSettingEditing(true)
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default SettingsGroupSubSetting
