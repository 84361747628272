import { useEffect, useState } from 'react'
import {
    onSnapshot,
    updateDoc,
    doc,
    DocumentReference,
    DocumentData,
} from 'firebase/firestore'
import moment from 'moment'
import { useParams, Link } from 'react-router-dom'

import ContentArea from '../../components/ContentArea'
import ContentDetail from '../../components/ContentDetail'
import { db } from '../../config'
import { useUser } from '../../components/UserContext'
import { useFirestoreListener } from '../../components/FirestoreListenerContext'
import LeadIconBlack from '../../assets/images/LeadIconBlack.svg'
import NoteIcon from '../../assets/images/NoteIcon.svg'
import { parsePhoneToReadable } from '../../utils/phoneParsing'
import { lead_type_map_readable } from '../../utils/leadMaps'
import BackArrow from '../../assets/images/BackArrow.svg'
import NewNoteModal from './NewNoteModal'
import Loading from '../../components/Loading'
import UpdateNoteModal from './UpdateNoteModal'
import DeleteNoteModal from './DeleteNoteModal'
import RequestCreditModal from './RequestCreditModal'
import DynamicLeadInfo from '../../components/DynamicLeadInfo'

function LeadDetail() {
    const [lead, setLead] = useState<null | any>(null)
    const [leadRef, setLeadRef] =
        useState<DocumentReference<DocumentData> | null>(null)
    const [questionLabels, setQuestionLabels] = useState<any[]>([])

    const { addUnsubscribeFunction } = useFirestoreListener()
    const { lead_id } = useParams()
    const { userData } = useUser()

    console.log(lead)

    useEffect(() => {
        async function fetchQuestionLabels() {
            try {
                const response = await fetch(
                    'https://admin.firmleads.io/api/funnel-questions?limit=333'
                )
                const data = await response.json()
                console.log(data.docs.length)
                setQuestionLabels(data.docs ?? [])
            } catch (error) {
                console.error('Error fetching question labels:', error)
            }
        }

        fetchQuestionLabels()
    }, [])

    useEffect(() => {
        function handleSearch() {
            try {
                const docRef = doc(db, 'leads', lead_id!)

                const unsubscribe = onSnapshot(docRef, (snapshot) => {
                    setLead({
                        id: snapshot.id,
                        ...snapshot.data(),
                    })
                })
                addUnsubscribeFunction(unsubscribe)
                setLeadRef(docRef)
            } catch (error) {
                console.error('error searching leads:', error)
            }
        }

        handleSearch()
    }, [lead_id])

    const handleUpdate = async (update: any) => {
        lead.notes[update.index] = update.value

        await setLead(lead)
        await updateDoc(leadRef!, { notes: lead.notes })
    }

    const handleDelete = async (index: number) => {
        lead.notes.splice(index, 1)

        await setLead(lead)
        await updateDoc(leadRef!, { notes: lead.notes })
    }

    const updateChargeClient = async (charge: boolean) => {
        setLead({ ...lead, charge_client: charge })
        try {
            await updateDoc(leadRef!, {
                charge_client: charge,
            })
        } catch (error) {
            console.error('Error updating Firestore document:', error)
        }
    }

    if (!lead || !leadRef) {
        return (
            <div className="flex h-screen items-center justify-center">
                <Loading />
            </div>
        )
    }

    return (
        <ContentArea>
            <div className="flex ">
                <Link to="/leads" className="mr-6 mt-2 cursor-pointer">
                    <img src={BackArrow} alt="back arrow" />
                </Link>
                <h1 className="mb-8">
                    {lead.first_name + ' ' + lead.last_name}
                </h1>
            </div>
            <ContentDetail>
                <div className="mb-8 flex">
                    <img
                        src={LeadIconBlack}
                        className="mt-2 h-5"
                        alt="lead icon"
                    />
                    <span className="ml-2 text-[24px] font-semibold text-Navy">
                        View Lead
                    </span>
                </div>
                <table className="w-full text-Navy">
                    <tbody>
                        <tr className="base16 text-left text-textGrey200">
                            <th className="pb-2 font-normal">Name</th>

                            <th className="pb-2 font-normal">Email</th>
                            <th className="pb-2 font-normal">Phone</th>
                        </tr>
                        <tr className="base16">
                            <td>{lead.first_name + ' ' + lead.last_name}</td>

                            <td className="text-Red">
                                <a href={`mailto:${lead.email}`}>
                                    {lead.email}
                                </a>
                            </td>
                            <td className="text-Red">
                                <a href={`tel:${lead.phone}`}>
                                    {parsePhoneToReadable(lead.phone)}
                                </a>
                            </td>
                        </tr>
                        <tr className="base16  text-left text-textGrey200">
                            <th className="pb-2 pt-12 font-normal">Category</th>
                            <th className="pb-2 pt-12 font-normal">
                                Jurisdiction
                            </th>

                            <th className="pb-2 pt-12 font-normal">Created</th>
                        </tr>

                        <tr>
                            <td className="capitalize">
                                {lead_type_map_readable[lead.charge_category]}
                            </td>
                            <td>{lead.city + ', ' + lead.state}</td>

                            <td>
                                {moment(lead.created.toDate()).format(
                                    'MM/DD/YYYY hh:mm a'
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ContentDetail>
            <ContentDetail className="mt-9">
                <div className="mb-8 flex">
                    <img
                        src={LeadIconBlack}
                        className="mt-2 h-5"
                        alt="lead icon"
                    />
                    <span className="ml-2 text-[24px] font-semibold text-Navy">
                        Lead Entered Info
                    </span>
                </div>
                <table className="w-full text-Navy">
                    <tbody>
                        {/* {lead.budget && lead.budget.upper && (
                            <tr className="base16  text-left text-textGrey200">
                                <th className="pb-2 pt-12 font-normal">
                                    Lead's Budget
                                </th>
                            </tr>
                        )}

                        {lead.budget && lead.budget.upper && (
                            <tr>
                                <td>{`${formatCurrency(lead.budget.lower, false)}${lead.budget.upper >= 5000 ? '+' : ' - ' + formatCurrency(lead.budget.upper, false)}`}</td>
                            </tr>
                        )} */}
                        <DynamicLeadInfo lead={lead} labels={questionLabels} />
                        <tr className="base16  text-left text-textGrey200">
                            <th
                                colSpan={3}
                                className={`pb-2 ${lead.funnel_responses.length > 1 ? 'pt-12' : ''} font-normal`}
                            >
                                Issue
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={2} className="font-semibold">
                                {lead.funnel_responses &&
                                    lead.funnel_responses.find(
                                        (item: any) =>
                                            item.type === 'description'
                                    ).value}
                            </td>
                            {lead.credit_requested ? (
                                <td className="text-Green">Credit Requested</td>
                            ) : moment(lead.created.toDate()).isBefore(
                                  moment().subtract(14, 'days')
                              ) ? (
                                <td></td>
                            ) : (
                                <td>
                                    <RequestCreditModal
                                        lead_ref={leadRef!}
                                        lawyer_id={lead.lawyer_id}
                                        lead_name={
                                            lead.first_name +
                                            ' ' +
                                            lead.last_name
                                        }
                                        lead_created={lead.created}
                                        lead_charge_category={
                                            lead.charge_category
                                        }
                                    />
                                </td>
                            )}
                        </tr>
                        {/* <tr className="base16  text-left text-textGrey200">
                        <th className="pb-2 pt-12 font-normal">Created</th>
                    </tr>

                    <tr>
                        <td>
                            {moment(lead.created.toDate()).format(
                                'MM/DD/YYYY hh:mm a'
                            )}
                        </td>
                    </tr> */}
                        {userData!.role === 'admin' && (
                            <tr className="base16  text-left text-textGrey200">
                                <th className="pb-2 pt-12 font-normal">ID</th>
                                <th className="pb-2 pt-12 font-normal">
                                    Lawyer ID
                                </th>
                                <th className="pb-2 pt-12 font-normal">
                                    Charge Client
                                </th>
                            </tr>
                        )}
                        {userData!.role === 'admin' && (
                            <tr>
                                <td>{lead.id}</td>
                                <td>{lead.lawyer_id}</td>
                                <td>
                                    <div>
                                        <label className="mr-5">
                                            <input
                                                type="radio"
                                                value="true"
                                                checked={lead.charge_client}
                                                onChange={(e) => {
                                                    updateChargeClient(
                                                        e.target.value ===
                                                            'true'
                                                    )
                                                }}
                                                className="mr-2"
                                            />
                                            Yes
                                        </label>

                                        <label>
                                            <input
                                                type="radio"
                                                value="false"
                                                checked={!lead.charge_client}
                                                onChange={(e) => {
                                                    updateChargeClient(
                                                        e.target.value ===
                                                            'true'
                                                    )
                                                }}
                                                className="mr-2"
                                            />
                                            No
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </ContentDetail>
            <ContentDetail className="mt-9">
                <div className="mb-8 flex">
                    <img src={NoteIcon} className="mt-2 h-5" alt="note icon" />
                    <span className="ml-2 text-[24px] font-semibold text-Navy">
                        Notes
                    </span>
                </div>
                <div className="mb-8">
                    {lead &&
                        lead.notes &&
                        lead.notes.length > 0 &&
                        lead.notes.map((note: any, index: number) => {
                            return (
                                <div className="mb-3 flex w-full justify-between border-b-[1px] border-borderGrey px-6 py-6 pt-3">
                                    <div className="max-w-[750px]">
                                        <span className="base16 font-semibold text-Navy">
                                            {note.title}
                                        </span>
                                        <p className="text-textGrey200">
                                            {note.note}
                                        </p>
                                        <p className="mt-1 text-textGrey100">
                                            Create by {note.lawyer_name} on{' '}
                                            {moment(
                                                note.timestamp.toDate()
                                            ).format('MMM DD, YYYY hh:mm A')}
                                        </p>
                                    </div>
                                    <div className="flex px-6">
                                        <UpdateNoteModal
                                            title_start={note.title}
                                            note_start={note.note}
                                            index={index}
                                            handleUpdate={handleUpdate}
                                        />
                                        <DeleteNoteModal
                                            index={index}
                                            handleDelete={handleDelete}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                </div>
                <NewNoteModal lead_id={lead.id} leadRef={leadRef!} />
            </ContentDetail>
        </ContentArea>
    )
}

export default LeadDetail
