import React from 'react'

interface LoadingProps {
    className?: string
}

function Loading({ className }: LoadingProps) {
    return (
        <div
            className={
                'loader h-10 w-10 rounded-full border-4 border-t-[5px] border-borderGrey border-t-Red ' +
                className
            }
        ></div>
    )
}

export default Loading
