import React, { useState } from 'react'
// import PracticeStateSingleSelector from '../../PracticeStateSingleSelector'
import { Switch } from '@headlessui/react'
import CountySuggest from '../../CountySuggest'

interface SetStateJurisdictionProps {
    state: string
    state_data: { entire_state: boolean; counties: string[] }
    jurisdiction: {
        [key: string]: { entire_state: boolean; counties: string[] }
    }
    setJurisdiction: React.Dispatch<
        React.SetStateAction<{
            [key: string]: { entire_state: boolean; counties: string[] }
        }>
    >
}

const SetStateJurisdiction: React.FC<SetStateJurisdictionProps> = ({
    state,
    state_data,
    jurisdiction,
    setJurisdiction,
}) => {
    const [entireState, setEntireState] = useState(state_data.entire_state)
    const [selectedCounties, setSelectedCounties] = useState<string[]>(
        state_data.counties
    )

    function handleSetEntireState(entireState: boolean) {
        jurisdiction[state]['entire_state'] = entireState
        setJurisdiction(jurisdiction)
        setEntireState(entireState)
    }

    function handleSetSelectedCounties(counties: string[]) {
        setSelectedCounties(counties)
        jurisdiction[state] = {
            entire_state: false,
            counties: counties,
        }
        setJurisdiction(jurisdiction)
    }

    return (
        <div>
            <div className="flex justify-between pb-6">
                <div className="">
                    <p className="font-semibold">
                        Do you service the entire state?
                    </p>
                </div>
                <Switch
                    checked={entireState}
                    onChange={handleSetEntireState}
                    className={`${entireState ? 'bg-Red' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                    <span
                        className={`${entireState ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white`}
                    />
                </Switch>
            </div>

            {!entireState && (
                <div>
                    <p className="pb-2 font-semibold">Select Counties</p>
                    <CountySuggest
                        state={state}
                        selectedCounties={selectedCounties}
                        clearOnStateChange={false}
                        setSelectedCounties={handleSetSelectedCounties}
                    />
                </div>
            )}
        </div>
    )
}

export default SetStateJurisdiction
