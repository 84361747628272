import React, { useState, useRef } from 'react'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { DocumentReference, DocumentData, updateDoc } from 'firebase/firestore'

import { storage } from '../../../config'
import ButtonClear from '../../../components/ButtonClear'
import Loading from '../../../components/Loading'

interface ImageUploadProps {
    buttonText: string
    profileRef: DocumentReference<DocumentData>
    updateFieldName: string
    uploadFolder: string
    updateAuthPhoto?: (url: string) => void
}

const ImageUpload: React.FC<ImageUploadProps> = ({
    buttonText,
    profileRef,
    updateFieldName,
    uploadFolder,
    updateAuthPhoto,
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [loading, setLoading] = useState(false)

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0]
            uploadImage(file)
        }
    }
    const handleButtonClick = () => {
        fileInputRef.current?.click()
    }

    const uploadImage = async (file: File) => {
        setLoading(true)
        const storageRef = ref(storage, `${uploadFolder}/${file.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                )
            },
            (error) => {
                console.log(error)
                setLoading(false)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(
                    async (downloadURL) => {
                        await updateDoc(profileRef, {
                            [updateFieldName]: downloadURL,
                        })
                        if (updateAuthPhoto) {
                            updateAuthPhoto(downloadURL)
                        }

                        setLoading(false)
                    }
                )
            }
        )
    }

    return (
        <div className="container mx-auto p-4">
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleImageChange}
                        className="hidden"
                    />
                    <ButtonClear
                        title={buttonText}
                        onClick={handleButtonClick}
                        className=" hover:underline"
                    />
                </div>
            )}
        </div>
    )
}

export default ImageUpload
