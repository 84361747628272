import { Outlet, useRoutes } from 'react-router-dom'

import './index.css'
import BaseLayout from './layouts/BaseLayout'
import Leads from './pages/Leads'
import LeadDetail from './pages/LeadDetail'
import Invoices from './pages/Invoices'
import InvoiceDetail from './pages/InvoiceDetail'
import Billing from './pages/Billing'
import CreditRequests from './pages/CreditRequests'
import CreditRequestDetail from './pages/CreditRequestDetail'
import Profile from './pages/Profile'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import Support from './pages/Support'
import ForgotPassword from './pages/ForgotPassword'
import PrivateWrapper from './components/PrivateWrapper'
import { useEffect } from 'react'

const RedirectToExternal = ({ url }: { url: string }) => {
    useEffect(() => {
        window.location.href = url
    }, [url])

    return null
}

function Router() {
    return (
        <div>
            {useRoutes([
                {
                    path: '/',
                    element: (
                        <PrivateWrapper>
                            <BaseLayout />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <Leads /> }],
                },
                {
                    path: '/leads',
                    element: (
                        <PrivateWrapper>
                            <BaseLayout />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <Leads /> }],
                },
                {
                    path: '/leads/:lead_id',
                    element: (
                        <PrivateWrapper>
                            <BaseLayout />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <LeadDetail /> }],
                },
                {
                    path: '/invoices',
                    element: (
                        <PrivateWrapper>
                            <BaseLayout />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <Invoices /> }],
                },
                {
                    path: '/invoices/:invoice_id',
                    element: (
                        <PrivateWrapper>
                            <BaseLayout />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <InvoiceDetail /> }],
                },
                {
                    path: '/billing',
                    element: (
                        <PrivateWrapper>
                            <BaseLayout />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <Billing /> }],
                },
                {
                    path: '/credits',
                    element: (
                        <PrivateWrapper>
                            <BaseLayout />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <CreditRequests /> }],
                },
                {
                    path: '/credits/:credit_id',
                    element: (
                        <PrivateWrapper>
                            <BaseLayout />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <CreditRequestDetail /> }],
                },
                {
                    path: '/profile/:lawyer_id',
                    element: (
                        <PrivateWrapper>
                            <BaseLayout />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <Profile /> }],
                },
                // {
                //     path: '/config',
                //     element: (
                //         <PrivateWrapper>
                //             <Outlet />
                //         </PrivateWrapper>
                //     ),
                //     children: [{ path: '', element: <Config /> }],
                // },
                {
                    path: '/login',
                    element: <Outlet />,
                    children: [{ path: '', element: <SignIn /> }],
                },
                {
                    path: '/signup',
                    element: <Outlet />,
                    children: [{ path: '', element: <SignUp /> }],
                },
                {
                    path: '/forgotpassword',
                    element: <Outlet />,
                    children: [{ path: '', element: <ForgotPassword /> }],
                },
                {
                    path: '/support',
                    element: (
                        <PrivateWrapper>
                            <BaseLayout />
                        </PrivateWrapper>
                    ),
                    children: [{ path: '', element: <Support /> }],
                },
                {
                    path: '/privacy',
                    element: (
                        <RedirectToExternal url="https://firmleads.io/privacy" />
                    ),
                    children: [{ path: '', element: <Support /> }],
                },
                {
                    path: '/terms',
                    element: (
                        <RedirectToExternal url="https://firmleads.io/terms" />
                    ),
                    children: [{ path: '', element: <Support /> }],
                },
            ])}
        </div>
    )
}

export default Router
