import { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { UserDataType, useUser } from '../../components/UserContext'
import Loading from '../../components/Loading'
import { useAuth } from '../../components/AuthContext'
import Config from '../../pages/AccountConfig'

const PrivateWrapper = ({ redirectPath = '/login', children }: any) => {
    const { userData, setUserData } = useUser()
    const { currentUser }: any = useAuth()

    useEffect(() => {
        if (currentUser && !userData) {
            const getUserRole = async () => {
                try {
                    const idTokenResult = await currentUser.getIdTokenResult()
                    setUserData({
                        id: currentUser.uid,
                        name: currentUser.displayName ?? '',
                        role: idTokenResult.claims.role ?? '',
                        email: currentUser.email ?? '',
                        picture: currentUser.photoURL ?? '',
                        configured: idTokenResult.claims.configured ?? false,
                    } as UserDataType)
                } catch (error) {
                    console.error('Error fetching ID token', error)
                }
            }

            getUserRole()
        }
    }, [currentUser]) // Dependency array includes currentUser

    if (!currentUser) return <Navigate to={redirectPath} />

    if (!userData) {
        return (
            <div className="flex h-screen w-full items-center justify-center">
                <Loading />
            </div>
        )
    }

    if (
        currentUser &&
        !userData.configured &&
        userData.role !== 'admin' &&
        userData.role !== 'employee'
    ) {
        return <Config />
    }

    return children ? children : <Outlet />
}

export default PrivateWrapper
