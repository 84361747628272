import { useEffect, useState, useCallback } from 'react'
import { httpsCallable } from 'firebase/functions'
import {
    doc,
    onSnapshot,
    DocumentReference,
    DocumentData,
    updateDoc,
} from 'firebase/firestore'

import { useUser } from '../../components/UserContext'
import { useFirestoreListener } from '../../components/FirestoreListenerContext'
import { functions, db } from '../../config'
import ContentArea from '../../components/ContentArea'
import ContentDetail from '../../components/ContentDetail'
import moment from 'moment'
import Loading from '../../components/Loading'
import UpdateBank from './UpdateBank'
import UpdateCard from './UpdateCard'
import UpdateBillingMethod from './UpdateBillingMethod'
import SettingsRow from '../../components/SettingsRow'
import { formatCurrency, toTitleCase } from '../../utils/leadMaps'
import PaymentMethodRow from '../../components/PaymentMethodRow'
import ProfileTextInput from '../../components/ProfileTextInput'
import { Switch } from '@headlessui/react'

function Billing() {
    const [billing, setBilling] = useState<null | any>(null)
    const [billingRef, setBillingRef] = useState<null | DocumentReference<
        DocumentData,
        DocumentData
    >>(null)
    const [linkToken, setLinkToken] = useState(null)

    const { userData } = useUser()
    const { addUnsubscribeFunction } = useFirestoreListener()

    const create_link_token = httpsCallable(
        functions,
        'payments-create_link_token'
    )

    const generateToken = useCallback(async () => {
        try {
            const res: any = await create_link_token({
                lawyer_id: userData?.id,
                lawyer_name: userData?.name,
            })

            setLinkToken(res.data.link_token)
        } catch (error) {
            console.error('create_link_token', error)
        }
    }, [setLinkToken])

    useEffect(() => {
        if (linkToken !== null) {
            return
        }

        generateToken()
    }, [linkToken])

    useEffect(() => {
        function handleSearch() {
            try {
                const docRef = doc(db, 'billing', userData!.id)
                setBillingRef(docRef)
                console.log('docRef', docRef)
                const unsubscribe = onSnapshot(docRef, (snapshot) => {
                    setBilling({
                        id: snapshot.id,
                        ...snapshot.data(),
                    })
                })
                addUnsubscribeFunction(unsubscribe)
            } catch (error) {
                console.error('error searching billing:', error)
            }
        }

        handleSearch()
    }, [])

    const handleUpdate = () => {
        updateDoc(billingRef!, {
            budget: billing.budget,
        })
    }
    const updateBudgetSet = () => {
        updateDoc(billingRef!, {
            budget_set: !billing.budget_set,
        })
    }

    const setBudgetAmount = (value: any) => {
        setBilling({
            ...billing,
            budget: parseInt(value),
        })
    }

    if (!billing) {
        return (
            <div className="flex h-screen items-center justify-center">
                <Loading />
            </div>
        )
    }

    return (
        <ContentArea>
            <div className="mx-auto max-w-[640px]">
                <div className="flex">
                    {/* <Link to="/billings" className="mr-6 mt-2 cursor-pointer">
                        <img src={BackArrow} />
                    </Link> */}
                    <h1 className="mb-8">Billing</h1>
                </div>
                <ContentDetail className=" mb-8 ">
                    <span className="base24 mb-8 font-semibold text-Navy">
                        Subscription
                    </span>

                    <SettingsRow
                        title="Account"
                        value={billing.email}
                        noEdit={true}
                    />
                    <SettingsRow
                        title="Status"
                        value={toTitleCase(billing.status)}
                        noEdit={true}
                    />
                    <SettingsRow
                        title="Plan Type"
                        value={toTitleCase(billing.plan_type)}
                        noEdit={true}
                    />
                    <SettingsRow
                        title="Next Billing Date"
                        value={moment(billing.member_since.toDate()).format(
                            'MM/DD/YYYY'
                        )}
                        noEdit={true}
                    />
                    <SettingsRow
                        title="Member Since"
                        value={moment(billing.member_since.toDate()).format(
                            'MM/DD/YYYY'
                        )}
                        noEdit={true}
                    />
                </ContentDetail>
                <ContentDetail className="mx-auto mb-8 max-w-[640px]">
                    <span className="base24 mb-8 font-semibold text-Navy">
                        Balances
                    </span>
                    <SettingsRow
                        title="Available Credit"
                        value={`$${billing.account_credit}.00`}
                        noEdit={true}
                        tooltipText="A pending amount to be applied to your next Invoice. Surplus credit is stored here and applied to future Invoices."
                    />
                    <SettingsRow
                        title="Pending Balance"
                        value={formatCurrency(billing.pending_balance)}
                        noEdit={true}
                        tooltipText="Total of all unprocessed Lead transactions (debit and credit) in the current billing cycle."
                    />
                    <SettingsRow
                        title="Past Due Balance"
                        value={formatCurrency(billing.due_balance)}
                        noEdit={true}
                        tooltipText="The past due balance from the previous billing cycle. Includes plan cost (if any) and leads."
                    />
                    <SettingsRow
                        title="Total Balance"
                        value={formatCurrency(
                            billing.pending_balance +
                                billing.due_balance -
                                billing.account_credit
                        )}
                        noEdit={true}
                        tooltipText="The past due balance from the previous billing cycle. Includes plan cost (if any) and leads."
                    />
                </ContentDetail>
                <ContentDetail className="mb-8">
                    <span className="base24 mb-8 font-semibold text-Navy">
                        Billing Methods
                    </span>
                    {billing.bank_accounts &&
                        billing.bank_accounts.length > 0 &&
                        billing.bank_accounts.map(
                            (bank_account: any, index: number) => {
                                return (
                                    <PaymentMethodRow
                                        key={index}
                                        title={`Bank Account`}
                                        value={`${bank_account.name} *****${bank_account.mask}`}
                                        type="bank"
                                        isDefault={
                                            bank_account.id ===
                                            billing.charge_account_id
                                        }
                                        remove={() => {}}
                                        noEdit={true}
                                    />
                                )
                            }
                        )}
                    {billing.card.last4 && (
                        <PaymentMethodRow
                            title={`${toTitleCase(billing.card.type)} ****${billing.card.last4}`}
                            value={`Expiration: ${billing.card.exp_month}/${billing.card.exp_year}`}
                            type="bank"
                            isDefault={
                                billing.card.id === billing.charge_account_id
                            }
                            remove={() => {}}
                            noEdit={true}
                        />
                    )}
                    <div className="flex">
                        <UpdateCard
                            buttonText={
                                billing.card.last4 !== ''
                                    ? 'Update Card'
                                    : 'Add Card'
                            }
                            className="mr-4"
                        />
                        <UpdateBank
                            linkToken={linkToken}
                            buttonText={
                                billing.bank_accounts.length > 0
                                    ? 'Update Bank'
                                    : 'Add Bank'
                            }
                        />
                    </div>
                    {/* <PaymentMethodRow  */}
                </ContentDetail>
                {/* <p className="base16 mb-4 text-textGrey200">
                    This information is required, and will be shown publicly on
                    our websites, unless overridden by a Public Profile value.
                </p> */}

                <ContentDetail className="mb-8">
                    <span className="base24 pb-8 font-semibold text-Navy">
                        Change Billing Method
                    </span>
                    <div className="w-full text-Navy">
                        <p className="base16 text-textGrey200">
                            Enter new or updated billing method details to
                            replace the current one on file. Cards are charge a
                            3% fee.
                        </p>
                        <p className="base16 mt-8 font-semibold">
                            Charge Account
                        </p>
                        <UpdateBillingMethod
                            billingRef={billingRef!}
                            bankOptions={billing.bank_accounts}
                            cardOption={billing.card}
                            defaultValue={billing.charge_account_id}
                        />
                    </div>
                </ContentDetail>
                <ContentDetail>
                    <span className="base24 pb-8 font-semibold text-Navy">
                        Budget
                    </span>
                    <p>
                        Set the maximum amount you would like to be charged per
                        pay period.
                    </p>
                    <div className=" border-b-[1px] border-borderGrey py-6">
                        <div className="flex justify-between">
                            <div className="">
                                <p className="font-semibold">Use Budget</p>
                            </div>
                            <Switch
                                checked={billing.budget_set}
                                onChange={updateBudgetSet}
                                className={`${billing.budget_set ? 'bg-Red' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                                <span
                                    className={`${billing.budget_set ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white`}
                                />
                            </Switch>
                        </div>
                    </div>

                    {billing.budget_set && (
                        <SettingsRow
                            title="Budget"
                            // description=""
                            value={`$${billing.budget}.00`}
                            onSave={handleUpdate}
                        >
                            <ProfileTextInput
                                className="mr-3 min-w-[290px]"
                                value={billing.budget}
                                setter={setBudgetAmount}
                                type="number"
                            />
                        </SettingsRow>
                    )}
                </ContentDetail>
            </div>
        </ContentArea>
    )
}

export default Billing
