import { useEffect, useState } from 'react'
import { onSnapshot, doc } from 'firebase/firestore'
import moment from 'moment'
import { useParams, Link } from 'react-router-dom'

import ContentArea from '../../components/ContentArea'
import ContentDetail from '../../components/ContentDetail'
import NoteIcon from '../../assets/images/NoteIcon.svg'
import { db } from '../../config'
import { useFirestoreListener } from '../../components/FirestoreListenerContext'
import Loading from '../../components/Loading'
import InvoiceIcon from '../../assets/images/InvoiceIcon.svg'
import BackArrow from '../../assets/images/BackArrow.svg'
import Button from '../../components/Button'
import { formatCurrency } from '../../utils/leadMaps'

function InvoiceDetail() {
    const [invoice, setInvoice] = useState<null | any>(null)

    const { addUnsubscribeFunction } = useFirestoreListener()
    const { invoice_id } = useParams()

    useEffect(() => {
        function handleSearch() {
            try {
                const docRef = doc(db, 'invoices', invoice_id!)

                const unsubscribe = onSnapshot(docRef, (snapshot) => {
                    setInvoice({
                        id: snapshot.id,
                        ...snapshot.data(),
                    })
                })
                addUnsubscribeFunction(unsubscribe)
            } catch (error) {
                console.error('error searching invoices:', error)
            }
        }

        handleSearch()
    }, [invoice_id])

    if (!invoice) {
        return (
            <div className="flex h-screen items-center justify-center">
                <Loading />
            </div>
        )
    }
    return (
        <ContentArea>
            <div className="flex ">
                <Link to="/invoices" className="mr-6 mt-2 cursor-pointer">
                    <img src={BackArrow} alt="back arrow" />
                </Link>
                <h1 className="mb-8">
                    {moment(invoice.issue_date.toDate()).format('MMM DD, YYYY')}
                </h1>
            </div>
            <ContentDetail className="mb-8">
                <div className="mb-8 flex justify-between">
                    <div className="flex">
                        <img
                            src={InvoiceIcon}
                            className="h-5 mt-2"
                            alt="invoice icon"
                        />
                        <span className="ml-2 text-[24px] font-semibold">
                            Invoice
                        </span>
                    </div>
                    <div>
                        <span
                            className={
                                'base14 h-full w-24 rounded-xl px-4 py-1 text-center font-bold capitalize' +
                                (invoice.status === 'paid'
                                    ? ' bg-bgGreen text-Green'
                                    : invoice.status ===
                                        'unpaid'
                                        ? ' bg-bgRed text-Red'
                                        : ' bg-bgSecondColor text-SecondColor')
                            }
                        >
                            {invoice.status}
                        </span>
                    </div>
                </div>
                <table className="w-full text-Navy">
                    <tr className="base16 text-left text-textGrey200">
                        <th className="pb-2 font-normal">Invoice #</th>
                        <th className="pb-2 font-normal">Issue Date</th>
                        <th className="pb-2 font-normal">Due Date</th>
                        <th className="pb-2 font-normal">Total</th>
                    </tr>
                    <tr className="base16">
                        <td>{invoice.xero_invoice_number}</td>
                        <td className="">
                            {moment(invoice.issue_date.toDate()).format(
                                'MM/DD/YYYY'
                            )}
                        </td>
                        <td>
                            {moment(invoice.due_date.toDate()).format(
                                'MM/DD/YYYY'
                            )}
                        </td>
                        <td>{formatCurrency(invoice.total)}</td>
                    </tr>
                    <tr className="base16  text-left text-textGrey200">
                        <th colSpan={4} className="pb-2 pt-12 font-normal">
                            Reference
                        </th>
                    </tr>
                    <tr>
                        <td className=" align-top" colSpan={3}>
                            {invoice.reference}
                        </td>
                        <td className="w-[200px]">
                            <a
                                href={invoice.invoice_url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button
                                    title="View Invoice"
                                    className=" px-9 py-[18px]"
                                />
                            </a>
                        </td>
                        {/* <td>
                            {moment(invoice.updated.toDate()).format(
                                'MM/DD/YYYY hh:mm a'
                            )}
                        </td>
                        <td colSpan={5} className="font-semibold">
                            {invoice.detail}
                        </td> */}
                    </tr>
                </table>
            </ContentDetail>
            <ContentDetail>
                <div className="mb-8 flex">
                    <img src={NoteIcon} className="h-5 mt-2" alt="note icon" />
                    <span className="ml-2 text-[24px] font-semibold text-Navy">
                        Line Items
                    </span>
                </div>
                <table className="w-full text-Navy">
                    <tr className="base16 text-left text-textGrey200">
                        <th className="pb-2 font-normal">Description</th>
                        <th className="pb-2 font-normal">Quantity</th>
                        <th className="pb-2 font-normal">Price</th>
                        <th className="pb-2 font-normal">Total</th>
                    </tr>
                    {invoice.line_items &&
                        invoice.line_items.length > 0 &&
                        invoice.line_items.map((item: any) => {
                            return (
                                <tr className="base16">
                                    <td className="border-b-[1px] border-borderGrey pb-4 pt-4">
                                        {item.description}
                                    </td>
                                    <td className="border-b-[1px] border-borderGrey pb-4 pt-4">
                                        {item.quantity}
                                    </td>
                                    <td className="border-b-[1px] border-borderGrey pb-4 pt-4">
                                        {formatCurrency(item.price)}
                                    </td>
                                    <td className="border-b-[1px] border-borderGrey pb-4 pt-4">
                                        {formatCurrency(item.amount)}
                                    </td>
                                </tr>
                            )
                        })}
                    <tr>
                        <td className=" pb-4 pt-4"></td>
                        <td className="border-b-[1px] border-borderGrey pb-4 pt-10 font-semibold">
                            Subtotal
                        </td>
                        <td className="border-b-[1px] border-borderGrey pb-4 pt-10"></td>
                        <td className="border-b-[1px] border-borderGrey pb-4 pt-10">
                            {formatCurrency(invoice.total)}
                        </td>
                    </tr>
                    <tr>
                        <td className=" pb-4 pt-4"></td>
                        <td className="border-b-[1px] border-borderGrey pb-4 pt-4 font-semibold">
                            Total Tax
                        </td>
                        <td className="border-b-[1px] border-borderGrey pb-4 pt-4"></td>
                        <td className="border-b-[1px] border-borderGrey pb-4 pt-4">
                            {/* {formatCurrency(invoice.total)} */}
                            $0.00
                        </td>
                    </tr>
                    <tr>
                        <td className=" pb-4 pt-4"></td>
                        <td className="pb-4 pt-4 font-semibold">Total</td>
                        <td className=" pb-4 pt-4"></td>
                        <td className=" pb-4 pt-4 font-semibold">
                            {formatCurrency(invoice.total)}
                        </td>
                    </tr>
                </table>
            </ContentDetail>
        </ContentArea>
    )
}

export default InvoiceDetail
