import { useState } from 'react'
import { Timestamp } from 'firebase/firestore'

import Button from '../../../components/Button'
import Modal from '../../../components/Modal'
import NoteIcon from '../../../assets/images/NoteIcon.svg'
import { useUser } from '../../../components/UserContext'
import ButtonClear from '../../../components/ButtonClear'
import Loading from '../../../components/Loading'
import EditIcon from '../../../assets/images/EditIcon.svg'

interface UpdateNoteModalProps {
    title_start: string
    note_start: string
    index: number
    handleUpdate: (value: any) => void
}

function UpdateNoteModal({
    title_start,
    note_start,
    handleUpdate,
    index,
}: UpdateNoteModalProps) {
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState(title_start)
    const [note, setNote] = useState(note_start)
    const characterLimit = 650
    const { userData } = useUser()

    const handleNoteChange = (e: any) => {
        const text = e.target.value
        if (text.length <= characterLimit) {
            setNote(text)
        }
    }

    const handleSubmit = async () => {
        setLoading(true)

        await handleUpdate({
            index,
            value: {
                note,
                ...userData,
                timestamp: Timestamp.now(),
            },
        })

        setModalOpen(false)
        setLoading(false)
        setTitle('')
        setNote('')
    }
    return (
        <div className="h-8 w-8 min-w-8">
            <img
                src={EditIcon}
                onClick={() => {
                    setModalOpen(!modalOpen)
                }}
                className="h-[18px] w-[18px]"
                alt="edit icon"
            />

            <Modal visible={modalOpen}>
                <div className="w-[500px]">
                    <div className="mb-2 flex rounded-t-[16px] bg-bgGrey p-6">
                        <img src={NoteIcon} className="h-5" alt="note icon" />
                        <span className="ml-2 text-[24px] font-semibold text-Navy">
                            Update Note for Lead
                        </span>
                    </div>
                    <div className="rounded-b-[16px] px-6 pb-6">
                        <input
                            value={title}
                            onChange={(e: any) => setTitle(e.target.value)}
                            maxLength={100}
                            placeholder="Enter note title..."
                            className="mb-2 w-full rounded-[12px] border-[1px] border-borderGrey p-3 text-Navy focus:border-[2px] focus:border-textGrey100 focus:outline-none"
                        />
                        <textarea
                            value={note}
                            rows={5}
                            placeholder="Enter note..."
                            onChange={handleNoteChange}
                            className=" w-full rounded-[12px] border-[1px] border-borderGrey p-3 text-Navy focus:border-[2px] focus:border-textGrey100 focus:outline-none"
                            maxLength={650}
                        />
                        <p className="my-2 font-semibold text-Navy">
                            {characterLimit - note.length} Characters Remaining
                        </p>
                        <p className="bas12 text-textGrey100">
                            Notes entered into this section may be viewed by
                            FirmLeads. This area should not be used to document
                            privileged attorney-client communications.
                        </p>
                        {loading ? (
                            <div className="mt-8 flex h-full w-full items-center justify-center">
                                <Loading />
                            </div>
                        ) : (
                            <div className="mt-8 flex justify-end">
                                <ButtonClear
                                    title="Cancel"
                                    onClick={() => {
                                        setModalOpen(!modalOpen)
                                    }}
                                    className="px-9 py-[18px] text-textGrey200"
                                />
                                <Button
                                    title="Create Note"
                                    className="px-9 py-[18px]"
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default UpdateNoteModal
