import React, { useEffect, useRef, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'

interface PracticeStateSelectorProps {
    displayState: string
    selectedState: string
    setSelectedState: (value: string) => void
    options?: string[]
}

const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
]

const PracticeStateSingleSelector: React.FC<PracticeStateSelectorProps> = ({
    displayState,
    selectedState,
    setSelectedState,
    options,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const dropdownRef = useRef<HTMLDivElement>(null)

    const isSelected = (value: string): boolean => {
        return selectedState === value
    }

    const handleSelect = (value: string) => {
        setSelectedState(value)
        setIsOpen(false)
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [dropdownRef])

    return (
        <div ref={dropdownRef} className="max-w-xs pb-8">
            <Listbox
                as="div"
                className="space-y-1"
                value={selectedState}
                onChange={(value) => handleSelect(value as any)}
            >
                {({ open }) => (
                    <>
                        {/* <Listbox.Label className="base16 font-semibold text-Navy ">
                            Select practice states
                        </Listbox.Label> */}
                        <div className="relative">
                            <span className="inline-block w-full shadow-sm">
                                <Listbox.Button
                                    className="border-MainColor focus-within:border-Neutral400 focus-within:outline-MainColor flex w-full items-center rounded-[12px] border-2 p-[8px] focus-within:outline focus-within:outline-2 focus-within:outline-offset-2 md:focus-within:outline-none"
                                    onClick={() => setIsOpen(!isOpen)}
                                >
                                    <span className="block truncate">
                                        {selectedState === ''
                                            ? 'Select state'
                                            : `${selectedState}`}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <svg
                                            className="h-5 w-5 text-gray-400"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            stroke="currentColor"
                                        >
                                            <path
                                                d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </span>
                                </Listbox.Button>
                            </span>

                            <Transition
                                unmount={false}
                                show={isOpen}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                className="absolute mt-1 w-full rounded-md bg-white shadow-lg"
                            >
                                <Listbox.Options
                                    static
                                    className="shadow-xs max-h-60 overflow-auto rounded-md py-1 text-base leading-6 focus:outline-none sm:text-sm sm:leading-5"
                                >
                                    {states.map((state, index) => {
                                        const selected = isSelected(state)
                                        return (
                                            <Listbox.Option
                                                key={index}
                                                value={state}
                                            >
                                                {({ active }) => (
                                                    <div
                                                        className={`${
                                                            active
                                                                ? 'bg-bgRed text-Navy'
                                                                : 'text-Navy'
                                                        } relative cursor-default select-none py-2 pl-8 pr-4`}
                                                    >
                                                        <span
                                                            className={`${
                                                                selected
                                                                    ? 'font-semibold'
                                                                    : 'font-normal'
                                                            } block truncate`}
                                                        >
                                                            {state}
                                                        </span>
                                                        {selected && (
                                                            <span
                                                                className={`${
                                                                    active
                                                                        ? 'text-white'
                                                                        : 'text-Red'
                                                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                                            >
                                                                <svg
                                                                    className="h-5 w-5"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                        clipRule="evenodd"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </Listbox.Option>
                                        )
                                    })}
                                </Listbox.Options>
                            </Transition>
                            {/* <div className="pt-2 text-sm capitalize">
                                {selectedState.length > 0 && (
                                    <>Selected states: {displayState}</>
                                )}
                            </div> */}
                        </div>
                    </>
                )}
            </Listbox>
        </div>
    )
}

export default PracticeStateSingleSelector
