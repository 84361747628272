import React, { ReactNode } from 'react'

interface THProps {
    children?: ReactNode
    className?: string
}

const TH: React.FC<THProps> = ({ children, className }) => {
    return (
        <th
            className={
                className +
                ' border-b-[1px] border-borderGrey py-3 font-semibold'
            }
        >
            {children}
        </th>
    )
}

export default TH
