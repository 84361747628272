import React, { useState } from 'react'

import ProfileTextInput from '../../../components/ProfileTextInput'
import { DocumentData, DocumentReference, updateDoc } from 'firebase/firestore'
import SettingsRow from '../../../components/SettingsRow'
import { useAuth } from '../../../components/AuthContext'
import { UserDataType, useUser } from '../../../components/UserContext'
import { updateProfile, updateEmail } from 'firebase/auth'
import {
    convert_to_e164,
    parsePhoneToReadable,
} from '../../../utils/phoneParsing'
import ProfilePhoneInput from '../../../components/ProfilePhoneInput'
import {
    parseJurisdiction,
    parsePracticeAreas,
} from '../../../utils/practiceAreas'
import PracticeAreaSelector from '../../../components/PracticeAreaSelector'
import SettingsGroupRow from '../../../components/SettingsGroupRow'
import SettingsGroupSubSetting from '../../../components/SettingGroupSubSetting'
import SetStateJurisdiction from '../../../components/JurisdictionSelector/SetStateJurisdiction'
import AddJurisdiction from '../../../components/JurisdictionSelector/AddJurisdiction'
// import SettingsGroupAddSetting from '../../../components/SettingsGroupAddSetting'

interface PersonalInfoProps {
    profile: any
    profileRef: DocumentReference<DocumentData>
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({ profile, profileRef }) => {
    const [first_name, setFirstName] = useState(profile.first_name)
    const [last_name, setLastName] = useState(profile.last_name)
    const [phone, setPhone] = useState(profile.phone)
    const [email, setEmail] = useState(profile.email)
    const [firm_name, setFirmName] = useState(profile.firm_name)
    const [street, setStreet] = useState(profile.firm_address.street)
    const [apartment, setApartment] = useState(profile.firm_address.apartment)
    const [city, setCity] = useState(profile.firm_address.city)
    const [state, setState] = useState(profile.firm_address.state)
    const [postcode, setPostcode] = useState(profile.firm_address.postcode)
    const [jurisdiction, setJurisdiction] = useState<{
        [key: string]: { entire_state: boolean; counties: string[] }
    }>(profile.jurisdiction)
    const [practice_areas, setPracticeAreas] = useState(profile.practice_areas)

    const [isSubSettingEditing, setIsSubSettingEditing] = useState(false)
    const [isAddingSetting, setIsAddingSetting] = useState(false)

    const { currentUser }: any = useAuth()
    const { userData, setUserData } = useUser()

    const updateAuthName = async () => {
        setUserData({
            ...userData,
            name: first_name + ' ' + last_name,
        } as UserDataType)
        await updateDoc(profileRef, {
            first_name,
            last_name,
        })
        updateProfile(currentUser, {
            displayName: first_name + ' ' + last_name,
        })
    }
    const updateAuthEmail = async () => {
        setUserData({
            ...userData,
            email,
        } as UserDataType)
        await updateDoc(profileRef, {
            email,
        })
        updateEmail(currentUser, email)
    }

    const updateAuthPhone = async () => {
        // setUserData({
        //     ...userData,
        //     phone: phone.replace(/\D/g, ''),
        // } as UserDataType)
        await updateDoc(profileRef, {
            phone: '+' + phone.replace(/\D/g, ''),
        })
        //updatePhoneNumber(currentUser, phone)
    }

    const updateFirmName = async () => {
        await updateDoc(profileRef, {
            firm_name,
        })
    }

    const updateFirmAddress = async () => {
        await updateDoc(profileRef, {
            firm_address: {
                street,
                apartment,
                city,
                state,
                postcode,
                ...profile.firm_address,
            },
        })
    }

    const removeStateJurisdiction = async (state: string) => {
        let newJurisdiction = { ...jurisdiction }
        delete newJurisdiction[state]
        setJurisdiction(newJurisdiction)
        await updateDoc(profileRef, {
            jurisdiction: newJurisdiction,
        })
    }

    const updateJurisdiction = async () => {
        await updateDoc(profileRef, {
            jurisdiction,
        })
    }

    const updatePracticeArea = async () => {
        await updateDoc(profileRef, {
            practice_areas,
        })
    }

    return (
        <div>
            <SettingsRow
                title="Full Name"
                description="This is the name that appears on your invoices and
                            to leads."
                onSave={updateAuthName}
                value={first_name + ' ' + last_name}
            >
                <div className="flex flex-wrap">
                    <ProfileTextInput
                        className="mr-16 w-[310px]"
                        title="First Name"
                        value={first_name}
                        setter={setFirstName}
                    />
                    <ProfileTextInput
                        className="mr-16 w-[310px]"
                        title="Last Name"
                        value={last_name}
                        setter={setLastName}
                    />
                </div>
            </SettingsRow>
            <SettingsRow
                title="Email"
                description="The email you use to login with. You can set a email for new leads below."
                onSave={updateAuthEmail}
                value={email}
            >
                <ProfileTextInput
                    className="mr-16 w-[310px]"
                    title="Email"
                    value={email}
                    setter={setEmail}
                />
            </SettingsRow>
            <SettingsRow
                title="Phone"
                description="The phone number contact and account verification from FirmLeads. You can set a phone number for new leads below."
                onSave={updateAuthPhone}
                value={parsePhoneToReadable(phone)}
            >
                <ProfilePhoneInput
                    className="mr-16 w-[310px]"
                    // title="Phone"
                    value={parsePhoneToReadable(phone)}
                    setter={(input: any) => {
                        setPhone(convert_to_e164(input))
                    }}
                />
            </SettingsRow>
            <SettingsRow
                title="Firm Name"
                description="This is the name that appears on your invoices and to new leads."
                onSave={updateFirmName}
                value={firm_name}
            >
                <ProfileTextInput
                    className="mr-16 w-[310px]"
                    title="Firm Name"
                    value={firm_name}
                    setter={setFirmName}
                />
            </SettingsRow>
            <SettingsRow
                title="Firm Address"
                description="This is the address that appears on your invoices and to new leads."
                onSave={updateFirmAddress}
                value={
                    street +
                    ' ' +
                    apartment +
                    ', ' +
                    city +
                    ', ' +
                    state +
                    ' ' +
                    postcode
                }
            >
                <div className="mt-4 flex flex-wrap">
                    <ProfileTextInput
                        className="mr-16 w-[310px]"
                        title="Address 1"
                        value={street}
                        setter={setStreet}
                    />
                    <ProfileTextInput
                        className="mr-16 w-[310px]"
                        title="Address 2"
                        value={apartment}
                        setter={setApartment}
                    />
                </div>
                <div className="mt-4 flex flex-wrap">
                    <ProfileTextInput
                        className="mr-16 w-[310px]"
                        title="City"
                        value={city}
                        setter={setCity}
                    />
                    <ProfileTextInput
                        className="mr-16 w-[310px]"
                        title="State"
                        value={state}
                        setter={setState}
                    />
                </div>
                <div className="mt-4 flex flex-wrap">
                    <ProfileTextInput
                        className="mr-16 w-[310px]"
                        title="Zip Code"
                        value={postcode}
                        setter={setPostcode}
                    />
                </div>
            </SettingsRow>
            <SettingsGroupRow
                title="Jurisdiction"
                description="Define your jurisdiction to receive leads."
                value={parseJurisdiction(jurisdiction)}
                newSettingButtonText={isAddingSetting ? 'Save' : 'Add State'}
                isSubSettingEditing={isSubSettingEditing}
                isAddingSetting={isAddingSetting}
                setIsAddingSetting={setIsAddingSetting}
                onSave={() => {
                    updateJurisdiction()
                }}
                key={state}
            >
                {Object.entries(jurisdiction).map(([state, data]) => (
                    <SettingsGroupSubSetting
                        title={state}
                        value={
                            data.entire_state
                                ? 'Entire state'
                                : 'Select counties'
                        }
                        setSubSettingEditing={setIsSubSettingEditing}
                        noEdit={isAddingSetting || isSubSettingEditing}
                        onRemove={() => {
                            removeStateJurisdiction(state)
                        }}
                        onSave={() => {
                            updateJurisdiction()
                        }}
                    >
                        <SetStateJurisdiction
                            state={state}
                            state_data={data}
                            jurisdiction={jurisdiction}
                            setJurisdiction={setJurisdiction}
                        />
                    </SettingsGroupSubSetting>
                ))}
                {isAddingSetting && (
                    <AddJurisdiction
                        jurisdiction={jurisdiction}
                        setJurisdiction={setJurisdiction}
                        setIsAddingSetting={setIsAddingSetting}
                    />
                )}
            </SettingsGroupRow>
            <SettingsRow
                title="Practice Areas"
                description="The areas of law that you practice"
                value={parsePracticeAreas(practice_areas)}
                onSave={() => updatePracticeArea()}
            >
                <PracticeAreaSelector
                    displayAreas={parsePracticeAreas(practice_areas)}
                    selectedAreas={practice_areas}
                    setSelectedAreas={setPracticeAreas}
                />
            </SettingsRow>
        </div>
    )
}

export default PersonalInfo
