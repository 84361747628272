import React, { ReactNode } from 'react'

interface ContentAreaProps {
    className?: string
    children: ReactNode
}

const ContentArea: React.FC<ContentAreaProps> = ({ children, className }) => {
    return (
        <div className={` ${className} min-h-screen bg-bgGrey p-8`}>
            {children}
            <div className="pt-[100px] text-center ">
                <p className="text-textGrey100">
                    Copyright &copy; 2024 FirmLeads LLC
                </p>
                <p className="text-textGrey100">Version 1.0.5</p>
            </div>
        </div>
    )
}

export default ContentArea
