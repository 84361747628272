import React, { useState } from 'react'
import { updateProfile } from 'firebase/auth'
import { DocumentData, DocumentReference, updateDoc } from 'firebase/firestore'

import ProfileTextInput from '../../../components/ProfileTextInput'
import ProfileAreaInput from '../../../components/ProfileTextArea'
import ImageUpload from '../ImageUpload'
import DefaultProfilePicture from '../../../assets/images/DefaultProfilePicture.svg'
import SettingsRow from '../../../components/SettingsRow'
import {
    convert_to_e164,
    parsePhoneToReadable,
} from '../../../utils/phoneParsing'
import { useAuth } from '../../../components/AuthContext'
import { UserDataType, useUser } from '../../../components/UserContext'
import ProfilePhoneInput from '../../../components/ProfilePhoneInput'

interface PublicProfileProps {
    profile: any
    profileRef: DocumentReference<DocumentData>
}

const PublicProfile: React.FC<PublicProfileProps> = ({
    profile,
    profileRef,
}) => {
    const [contact_email, setContactEmail] = useState(profile.contact_email)
    const [contact_phone, setContactPhone] = useState(profile.contact_phone)
    const [website, setWebsite] = useState(profile.website)
    // const [skype, setSkype] = useState(profile.skype)
    const [facebook, setFacebook] = useState(profile.facebook)
    const [linkedin, setLinkedin] = useState(profile.linkedin)
    const [twitter, setTwitter] = useState(profile.twitter)
    const [youtube, setYoutube] = useState(profile.youtube)
    const [bio, setBio] = useState(profile.bio)
    const { currentUser }: any = useAuth()
    const { userData, setUserData } = useUser()

    const handleUpdate = async (field_name: string, field_value: string) => {
        await updateDoc(profileRef, {
            [field_name]: field_value,
        })
    }

    const updateAuthPhoto = (url: string) => {
        updateProfile(currentUser, {
            photoURL: url,
        })
        setUserData({ ...userData, picture: url } as UserDataType)
    }

    return (
        <div>
            <div className=" flex w-full justify-center">
                <div className="max-[168px] mr-24 flex h-[168px]  items-center">
                    <img
                        src={
                            profile.picture === ''
                                ? DefaultProfilePicture
                                : profile.picture
                        }
                        alt="Profile head shot"
                        className="w-24 rounded-full"
                    />
                    <ImageUpload
                        buttonText="Upload Profile Picture"
                        profileRef={profileRef}
                        updateFieldName="picture"
                        uploadFolder="profile_pictures"
                        updateAuthPhoto={updateAuthPhoto}
                    />
                </div>
                <div className="ml-24 flex h-[168px] items-center">
                    <div className="max-w-[200px] ">
                        <img
                            src={
                                profile.logo === ''
                                    ? DefaultProfilePicture
                                    : profile.logo
                            }
                            alt="Firm Logo"
                            className={`${profile.logo === '' ? 'w-24' : 'h-auto'} my-auto w-full`}
                        />
                    </div>
                    <ImageUpload
                        buttonText="Upload Firm Logo"
                        profileRef={profileRef}
                        uploadFolder="firm_logos"
                        updateFieldName="logo"
                    />
                </div>
            </div>
            <SettingsRow
                title="Contact Email"
                description="The email address given to new leads to contact you."
                value={contact_email}
                onSave={() => handleUpdate('contact_email', contact_email)}
            >
                <ProfileTextInput
                    className="mr-3 min-w-[290px]"
                    // title="Contact Email"
                    value={contact_email}
                    setter={setContactEmail}
                />
            </SettingsRow>
            <SettingsRow
                title="Contact Phone"
                description="The phone number shown to a new lead, and directed to contact you."
                value={parsePhoneToReadable(contact_phone)}
                onSave={() => handleUpdate('contact_phone', contact_phone)}
            >
                <ProfilePhoneInput
                    className="mr-3 min-w-[290px]"
                    // title="Contact Phone"
                    value={contact_phone}
                    setter={(input: any) => {
                        setContactPhone(convert_to_e164(input))
                    }}
                />
            </SettingsRow>
            <SettingsRow
                title="Website URL"
                // description="Your website URL."
                value={website}
                onSave={() => handleUpdate('website', website)}
            >
                <ProfileTextInput
                    className="mr-3 min-w-[290px]"
                    // title="Website URL"s
                    value={website}
                    setter={setWebsite}
                />
            </SettingsRow>
            <SettingsRow
                title="LinkedIn"
                description="Your linkedIn profile"
                value={linkedin}
                onSave={() => handleUpdate('linkedin', linkedin)}
            >
                <ProfileTextInput
                    className="mr-3 min-w-[290px]"
                    // title="LinkedIn"
                    value={linkedin}
                    setter={setLinkedin}
                />
            </SettingsRow>
            {/* <SettingsRow
                title="Skype"
                description="Your skype username"
                value={skype}
                onSave={() => handleUpdate('skype', skype)}
            >
                <ProfileTextInput
                    className="mr-3 min-w-[290px]"
                    // title="Skype Username"
                    value={skype}
                    setter={setSkype}
                />
            </SettingsRow> */}
            <SettingsRow
                title="Facebook"
                description="Your facebook profile URL"
                value={facebook}
                onSave={() => handleUpdate('facebook', facebook)}
            >
                <ProfileTextInput
                    className="mr-3 min-w-[290px]"
                    // title="Facebook URL"
                    value={facebook}
                    setter={setFacebook}
                />
            </SettingsRow>
            <SettingsRow
                title="YouTube"
                description="Your YouTube channel URL"
                value={youtube}
                onSave={() => handleUpdate('youtube', youtube)}
            >
                <ProfileTextInput
                    className="mr-3 min-w-[290px]"
                    // title="YouTube URL"
                    value={youtube}
                    setter={setYoutube}
                />
            </SettingsRow>
            <SettingsRow
                title="Twitter"
                description="Your twitter profile URL"
                value={twitter}
                onSave={() => handleUpdate('twitter', twitter)}
            >
                <ProfileTextInput
                    className="mr-3 min-w-[290px]"
                    // title="Twitter"
                    value={twitter}
                    setter={setTwitter}
                />
            </SettingsRow>
            <SettingsRow
                title="About"
                description="Your bio shown to new leads"
                value={bio}
                onSave={() => handleUpdate('bio', bio)}
            >
                <div className="flex">
                    <ProfileAreaInput
                        className="mr-3 w-full"
                        value={bio}
                        setter={setBio}
                    />
                </div>
            </SettingsRow>
        </div>
    )
}

export default PublicProfile
