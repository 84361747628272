import { useEffect, useState } from 'react'
import {
    onSnapshot,
    query,
    collection,
    where,
    orderBy,
    DocumentReference,
    Timestamp,
    DocumentData,
} from 'firebase/firestore'
import moment from 'moment'

import ContentArea from '../../components/ContentArea'
import { db } from '../../config'
import { useUser } from '../../components/UserContext'
import { useFirestoreListener } from '../../components/FirestoreListenerContext'
import TD from '../../components/TableData'
import TH from '../../components/TableHeader'
import TR from '../../components/TableRow'
import EyeIcon from '../../assets/images/EyeIcon.svg'
import { parsePhoneToReadable } from '../../utils/phoneParsing'
import { lead_type_map_readable } from '../../utils/leadMaps'
import { Link } from 'react-router-dom'

export interface Lead {
    budget: { lower: number; upper: number }
    charge_category: string
    charge_client: boolean
    city: string
    created: Timestamp
    billed_invoice_id: string
    description: string
    email: string
    first_name: string
    funnel_responses: {
        type: string
        label: string
        value: string | number
        value_label?: string
    }[]
    ip_address: string
    landing_page: string
    landing_url: string
    last_name: string
    lawyer_id: string
    notes: Note[]
    phone: string
    state: string
    trustedform_cert_url: string
    updated: Timestamp
    user_agent: string
}

export interface Note {
    timestamp: Timestamp
    lawyer_name: string
    title: string
    note: string
}

export interface LeadMiniSnapshot extends Lead {
    id: string
    ref: DocumentReference<DocumentData, DocumentData>
}

const Leads: React.FC = () => {
    const [leads, setLeads] = useState<LeadMiniSnapshot[]>([])

    const { userData } = useUser()
    const { addUnsubscribeFunction } = useFirestoreListener()
    console.log(userData)

    useEffect(() => {
        async function handleSearch() {
            try {
                let leadsQuery
                if (userData?.role === 'client') {
                    leadsQuery = query(
                        collection(db, 'leads'),
                        where('lawyer_id', '==', userData!.id + ''),
                        where('charge_client', '==', true),
                        orderBy('created', 'desc')
                    )
                } else {
                    leadsQuery = query(
                        collection(db, 'leads'),
                        orderBy('created', 'desc')
                    )
                }

                const unsubscribe = onSnapshot(leadsQuery, (snapshot) => {
                    setLeads(
                        snapshot.docs.map((lead) => ({
                            id: lead.id,
                            ref: lead.ref,
                            ...(lead.data() as Lead),
                        }))
                    )
                })
                addUnsubscribeFunction(unsubscribe)
            } catch (error) {
                console.error('error searching leads:', error)
            }
        }

        handleSearch()
    }, [])

    return (
        <ContentArea>
            <h1 className="mb-8">Leads</h1>
            <div className="rounded-2xl bg-white">
                <table className="w-full">
                    <TR className="base16 text-left text-Navy">
                        {/* <TH >ID</TH> */}
                        <TH className="truncate pl-6">Category</TH>
                        {/* <TH>Jurisdiction</TH> */}
                        <TH className="truncate ">Name</TH>
                        <TH className="truncate ">Phone</TH>
                        <TH className="truncate ">Email</TH>
                        <TH className="truncate ">Created</TH>
                        <TH className="truncate ">View</TH>
                    </TR>
                    {leads.length > 0 &&
                        leads.map((lead: LeadMiniSnapshot) => {
                            return (
                                <TR
                                    className="cursor-pointer "
                                    key={lead.id}
                                    issue={
                                        lead!.funnel_responses
                                            ? (lead!.funnel_responses!.find(
                                                  (item: any) =>
                                                      item.type ===
                                                      'description'
                                              )?.value! as string)
                                            : lead.description
                                    }
                                    jurisdiction={lead.city + ', ' + lead.state}
                                >
                                    {/* <TD className="pl-6 text-Red">
                                        {lead.id.substring(0, 8)}
                                    </TD> */}
                                    <TD className="truncate pl-6">
                                        {
                                            lead_type_map_readable[
                                                lead.charge_category
                                            ]
                                        }
                                    </TD>
                                    {/* <TD className="truncate">{lead.city + ', ' + lead.state}</TD> */}
                                    <TD className="truncate">
                                        {lead.first_name + ' ' + lead.last_name}
                                    </TD>
                                    <TD className="truncate text-Red">
                                        <a href={`tel:${lead.phone}`}>
                                            {parsePhoneToReadable(lead.phone)}
                                        </a>
                                    </TD>
                                    <TD className="truncate text-Red">
                                        <a href={`mailto:${lead.email}`}>
                                            {lead.email}
                                        </a>
                                    </TD>
                                    <TD className="truncate">
                                        {moment(lead.created.toDate()).format(
                                            'MM/DD/YY'
                                        )}
                                    </TD>
                                    <TD className="truncate">
                                        <div className="flex">
                                            <Link to={`/leads/${lead.id}`}>
                                                <img
                                                    src={EyeIcon}
                                                    className="h-4"
                                                    alt="Eye Icon"
                                                />
                                            </Link>
                                            {/* <img
                                                src={DocIcon}
                                                className="ml-3 h-4"
                                                alt="Document Icon"
                                            /> */}
                                        </div>
                                    </TD>
                                </TR>
                            )
                        })}
                </table>
            </div>
        </ContentArea>
    )
}

export default Leads
