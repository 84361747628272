import React, { useState } from 'react'
import { sendPasswordResetEmail } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

import { auth } from '../../config'
import ContentDetail from '../../components/ContentDetail'
import LoginLogo from '../../assets/images/LoginLogo.svg'
import EmailIcon from '../../assets/images/EmailIcon.svg'
import Button from '../../components/Button'
import ButtonClear from '../../components/ButtonClear'
import Loading from '../../components/Loading'

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const sendReset = async () => {
        setLoading(true)
        try {
            await sendPasswordResetEmail(auth, email)
        } catch (error: any) {
            console.log(error)
            alert('Send reset link failed: ' + error.message)
            setLoading(false)
        } finally {
            alert(
                'Reset link sent if you have an account. Please check your email.'
            )
            setLoading(false)
        }
    }
    return (
        <div className="flex h-screen w-screen items-center justify-center bg-bgGrey">
            <div>
                <ContentDetail className=" h-[380px] w-[335px]">
                    <div className="mb-9 flex justify-center">
                        <img src={LoginLogo} alt="login logo" />
                    </div>
                    <p className="mb-4 text-center text-textGrey200">
                        We will send you instructions on how to reset your
                        password by email.
                    </p>
                    <div className="  mb-4 flex w-full items-center rounded-[12px] border-[1px] border-borderGrey px-3 py-[14px]">
                        <img src={EmailIcon} alt="email icon" />
                        <input
                            placeholder="Email"
                            type="email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            className="ml-[8px] w-full cursor-pointer border-l-[1px] border-borderGrey pl-3 focus:border-[2px] focus:border-textGrey100 focus:outline-none"
                        />
                    </div>

                    {!loading && (
                        <Button
                            title="Submit"
                            className="w-full py-3"
                            onClick={sendReset}
                        />
                    )}
                    {loading ? (
                        <Loading className="mx-auto" />
                    ) : (
                        <ButtonClear
                            title="Login"
                            className="mx-auto pt-3"
                            onClick={() => {
                                navigate('/login')
                            }}
                        />
                    )}
                </ContentDetail>
                <p className=" mt-8 text-center text-textGrey100">
                    &copy; 2024 FirmLeads LLC
                </p>
            </div>
        </div>
    )
}

export default ForgotPassword
