import React from 'react'
import ButtonClear from '../ButtonClear'

interface PaymentMethodProps {
    title: string
    value: string
    type: string
    remove: () => void
    setDefault?: () => void
    noEdit?: boolean
    children?: React.ReactNode
    isDefault?: boolean
}

const PaymentMethodRow: React.FC<PaymentMethodProps> = ({
    title,
    value,
    type,
    setDefault,
    remove,
    children,
    noEdit,
    isDefault,
}) => {
    return (
        <div className=" border-b-[1px] border-borderGrey py-6">
            <div className="flex justify-between">
                <div className="">
                    <div className="flex font-semibold">
                        <p className="">{title}</p>
                        {isDefault && (
                            <p className="base12 ml-2 rounded-[8px] bg-bgGrey p-1">
                                DEFAULT
                            </p>
                        )}
                    </div>
                    <p>{value}</p>
                </div>

                {!noEdit && (
                    <ButtonClear
                        title="Edit"
                        className="ml-6 !items-start p-0 underline"
                        onClick={() => {}}
                    />
                )}
            </div>
        </div>
    )
}

export default PaymentMethodRow
