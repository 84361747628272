import React from 'react'

interface ButtonClearProps {
    className: string
    title: string
    disabled?: boolean
    textBlack?: boolean
    onClick?: () => void
}

function ButtonClear({
    title,
    className,
    onClick,
    textBlack,
    disabled,
}: ButtonClearProps) {
    return (
        <button
            className={`
                ${className} flex items-center justify-center rounded-xl border-0  text-center text-base font-semibold capitalize ${textBlack ? 'text-Navy' : 'text-Red'}`}
            onClick={onClick}
            disabled={disabled}
        >
            {title}
        </button>
    )
}

export default ButtonClear
