import React, { useEffect, useRef, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'

import { lead_type_map_readable } from '../../utils/leadMaps'

interface PracticeAreaSelectorProps {
    displayAreas: string
    selectedAreas: string[]
    setSelectedAreas: React.Dispatch<React.SetStateAction<string[]>>
    options?: string[]
}

const areas = [
    'auto',
    'bankruptcy',
    'business',
    'divorce',
    'criminal',
    'dui',
    'estate',
    'employment',
    'family',
    'guardianship',
    'immigration',
    'injury',
    'landlord',
    'probate',
    'realestate',
    'tenant',
    'traffic',
]

const PracticeAreaSelector: React.FC<PracticeAreaSelectorProps> = ({
    displayAreas,
    selectedAreas,
    setSelectedAreas,
    options,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const dropdownRef = useRef<HTMLDivElement>(null)

    const isSelected = (value: string): boolean => {
        return selectedAreas.includes(value)
    }

    const handleSelect = (value: string) => {
        if (!isSelected(value)) {
            const selectedAreasUpdated = [
                ...selectedAreas,
                areas.find((el) => el === value) || value,
            ]
            setSelectedAreas(selectedAreasUpdated)
        } else {
            handleDeselect(value)
        }
        setIsOpen(true)
    }

    const handleDeselect = (value: string) => {
        const selectedAreasUpdated = selectedAreas.filter((el) => el !== value)
        setSelectedAreas(selectedAreasUpdated)
        setIsOpen(true)
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [dropdownRef])

    return (
        <div ref={dropdownRef} className="max-w-xs pb-8">
            <Listbox
                as="div"
                className="space-y-1"
                value={selectedAreas}
                onChange={(value) => handleSelect(value as any)}
            >
                {({ open }) => (
                    <>
                        <Listbox.Label className="base16 font-semibold text-Navy ">
                            Select practice areas
                        </Listbox.Label>
                        <div className="relative">
                            <span className="inline-block w-full  shadow-sm">
                                <Listbox.Button
                                    className="focus:shadow-outline-blue sm:base14 relative w-full cursor-default rounded-[12px] border border-borderGrey bg-white py-2 pl-3 pr-10 text-left transition duration-150 ease-in-out focus:border-[2px] focus:border-textGrey100 focus:outline-none sm:leading-5"
                                    onClick={() => setIsOpen(!isOpen)}
                                >
                                    <span className="block truncate">
                                        {selectedAreas.length < 1
                                            ? 'Select areas'
                                            : `Selected areas (${selectedAreas.length})`}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <svg
                                            className="h-5 w-5 text-gray-400"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            stroke="currentColor"
                                        >
                                            <path
                                                d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </span>
                                </Listbox.Button>
                            </span>

                            <Transition
                                unmount={false}
                                show={isOpen}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg"
                            >
                                <Listbox.Options
                                    static
                                    className="shadow-xs max-h-60 overflow-auto rounded-md py-1 text-base leading-6 focus:outline-none sm:text-sm sm:leading-5"
                                >
                                    {areas.map((area) => {
                                        const selected = isSelected(area)
                                        return (
                                            <Listbox.Option
                                                key={area}
                                                value={area}
                                            >
                                                {({ active }) => (
                                                    <div
                                                        className={`${
                                                            active
                                                                ? 'bg-bgRed text-Navy'
                                                                : 'text-Navy'
                                                        } relative cursor-default select-none py-2 pl-8 pr-4 `}
                                                    >
                                                        <span
                                                            className={`${
                                                                selected
                                                                    ? 'font-semibold'
                                                                    : 'font-normal'
                                                            } block truncate`}
                                                        >
                                                            {
                                                                lead_type_map_readable[
                                                                    area
                                                                ]
                                                            }
                                                        </span>
                                                        {selected && (
                                                            <span
                                                                className={`${
                                                                    active
                                                                        ? 'text-white'
                                                                        : 'text-Red'
                                                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                                            >
                                                                <svg
                                                                    className="h-5 w-5"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                        clipRule="evenodd"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </Listbox.Option>
                                        )
                                    })}
                                </Listbox.Options>
                            </Transition>
                            <div className="pt-2 text-sm capitalize">
                                {selectedAreas.length > 0 && (
                                    <>Selected areas: {displayAreas}</>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </Listbox>
        </div>
    )
}

export default PracticeAreaSelector
