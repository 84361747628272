import React from 'react'
import { Lead } from '../../pages/Leads'

interface DynamicLeadInfoProps {
    labels: any[]
    lead: Lead
}

const DynamicLeadInfo: React.FC<DynamicLeadInfoProps> = ({ lead }) => {
    const rows: any[] = []
    let currentHeaderRow: any[] = []
    let currentDataRow: any[] = []
    let isFirstRow = true

    if (!lead.funnel_responses) return <></>

    for (let index = 0; index < lead.funnel_responses.length; index++) {
        const { type, label, value, value_label } = lead.funnel_responses[index]

        if (type !== 'description') {
            currentHeaderRow.push(
                <th
                    key={`header-${label}`}
                    className={`pb-2 font-normal ${isFirstRow ? '' : 'pt-12'}`}
                >
                    {label}
                </th>
            )

            currentDataRow.push(
                <td key={`data-${label}`}>{value_label ?? value}</td>
            )
        }
        if (
            (currentHeaderRow.length === 3 ||
                index === lead.funnel_responses.length - 1) &&
            currentHeaderRow.length > 0
        ) {
            rows.push(
                <React.Fragment key={`row-${index}`}>
                    <tr className="base16 text-left text-textGrey200">
                        {currentHeaderRow}
                    </tr>
                    <tr>{currentDataRow}</tr>
                </React.Fragment>
            )
            currentHeaderRow = []
            currentDataRow = []
            isFirstRow = false
        }
    }

    return <>{rows}</>
}

export default DynamicLeadInfo

// if (index === labels.length - 1) {
//   console.log(
//       (currentHeaderRow.length === 3 ||
//           index === labels.length - 1) &&
//           currentHeaderRow.length > 0
//   )
//   console.log(
//       currentHeaderRow.length === 3 || index === labels.length - 1
//   )
//   console.log(currentHeaderRow.length > 0)
// }
