import React, { ReactNode } from 'react'

interface ContentDetailProps {
    children?: ReactNode
    className?: string
}

const ContentDetail: React.FC<ContentDetailProps> = ({
    children,
    className,
}) => {
    return (
        <div className={className + ' rounded-2xl bg-white p-6'}>
            {children}
        </div>
    )
}

export default ContentDetail
