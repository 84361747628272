import { useEffect, useState, useRef } from 'react'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
// import { getDoc, doc } from 'firebase/firestore'

import { auth, functions } from '../../config'
import ContentDetail from '../../components/ContentDetail'
import LoginLogo from '../../assets/images/LoginLogo.svg'
// import NoteIcon from '../../assets/images/NoteIcon.svg'
import EmailIcon from '../../assets/images/EmailIcon.svg'
import PasswordIcon from '../../assets/images/PasswordIcon.svg'
import BackArrow from '../../assets/images/BackArrow.svg'
import Button from '../../components/Button'
import ButtonClear from '../../components/ButtonClear'
import Loading from '../../components/Loading'
import { httpsCallable } from 'firebase/functions'
import { UserDataType, useUser } from '../../components/UserContext'
import EyeIcon from '../../assets/images/EyeIconPassword.svg'
// import { useAuth } from '../../components/AuthContext'

function SignUp() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const intervalRef = useRef<NodeJS.Timeout | null>(null)
    const [showPassword, setShowPassword] = useState(false)

    const navigate = useNavigate()
    const set_up_new_user = httpsCallable(functions, 'mgmt-setup_new_user')
    const { setUserData } = useUser()
    // const { currentUser }: any = useAuth()

    useEffect(() => {
        set_up_new_user({ warm_up: true })
        intervalRef.current = setInterval(
            async () => {
                try {
                    await set_up_new_user({ warm_up: true })
                } catch (error) {
                    console.error('Error in interval:', error)
                }
            },
            15 * 60 * 1000
        )
        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current)
            }
        }
    }, [])

    const signUp = async () => {
        setLoading(true)
        try {
            if (password !== confirmPassword) {
                alert('Passwords are not the same.')
                return
            }
            // const docSnap = await getDoc(doc(db, 'invite_codes', inviteCode))

            // console.log(docSnap)

            // if (!docSnap.exists()) {
            //     alert(
            //         'Only clients with an invite code can create an account. If you are a client, contact us and we will ensure you able to make an account.'
            //     )
            //     return
            // }

            const response = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            )
            const resp: any = await set_up_new_user({
                uid: response.user.uid,
                email: response.user.email,
            })

            setUserData(resp.data as UserDataType)
        } catch (error) {
            console.log(error)
            setLoading(false)
        } finally {
            navigate('/')
            setLoading(false)
        }
    }

    return (
        <div className="h-screen w-screen bg-bgGrey">
            <a href="https://www.firmleads.io" className="mt-6">
                <img className="p-6" src={BackArrow} alt="Back Arrow" />
            </a>
            <div className="flex items-center justify-center">
                <div>
                    <ContentDetail className=" w-[335px]">
                        <div className="mb-9 flex justify-center">
                            <img src={LoginLogo} alt="login logo" />
                        </div>
                        <div className="  mb-4 flex w-full items-center rounded-[12px] border-[1px] border-borderGrey px-3 py-[14px] focus-within:border-2 focus-within:border-textGrey100">
                            <img src={EmailIcon} alt="email icon" />
                            <input
                                placeholder="Email"
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                className="ml-[8px] w-full cursor-pointer border-l-[1px] border-borderGrey pl-3 outline-none"
                            />
                        </div>
                        <div className="mb-4 flex w-full items-center rounded-[12px] border-[1px] border-borderGrey px-3 py-[14px] focus-within:border-2 focus-within:border-textGrey100">
                            <img src={PasswordIcon} alt="password icon" />
                            <input
                                placeholder="Password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                                className="ml-[8px] w-full cursor-pointer border-l-[1px] border-borderGrey pl-3 outline-none"
                            />
                            <img
                                src={EyeIcon}
                                alt="eye icon"
                                className="w-[20px] cursor-pointer"
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        </div>
                        <div className="mb-10 flex w-full items-center rounded-[12px] border-[1px] border-borderGrey px-3 py-[14px] focus-within:border-2 focus-within:border-textGrey100">
                            <img src={PasswordIcon} alt="password icon" />
                            <input
                                placeholder="Confirm Password"
                                type={showPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value)
                                }}
                                className="ml-[8px] w-full cursor-pointer border-l-[1px] border-borderGrey pl-3 outline-none"
                            />
                            <img
                                src={EyeIcon}
                                alt="eye icon"
                                className="w-[20px] cursor-pointer"
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        </div>
                        {/* <div className="mb-3 flex w-full items-center rounded-[12px] border-[1px] border-borderGrey px-3 py-[14px] ">
                            <img
                                src={NoteIcon}
                                className="h-[18px]"
                                alt="note icon"
                            />
                            <input
                                placeholder="Invite Code"
                                type="text"
                                value={inviteCode}
                                onChange={(e) => {
                                    setInviteCode(e.target.value)
                                }}
                                className="ml-[8px] w-full cursor-pointer border-l-[1px] border-borderGrey pl-3 focus:border-[2px]  focus:border-textGrey100 focus:outline-none"
                            />
                        </div> */}

                        {!loading && (
                            <Button
                                title="Create Account"
                                className="mt-6 w-full py-3"
                                onClick={signUp}
                            />
                        )}
                        {loading ? (
                            <Loading className="mx-auto" />
                        ) : (
                            <ButtonClear
                                title="Login"
                                className="mx-auto pt-3"
                                onClick={() => {
                                    navigate('/login')
                                }}
                            />
                        )}
                    </ContentDetail>
                    <p className=" mt-8 text-center text-textGrey100">
                        &copy; 2024 FirmLeads LLC
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SignUp
