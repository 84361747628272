import React from 'react'
import InfoIcon from '../../../assets/images/InfoIcon.svg'
import ButtonClear from '../../../components/ButtonClear'
import Tooltip from '../../../components/Tooltip'
import Button from '../../../components/Button'

interface JurisdictionAcctConfigWrapperProps {
    isSubSettingEditing: boolean
    isAddingSetting: boolean
    newSettingButtonText: string
    setIsAddingSetting: (value: boolean) => void
    validate?: () => boolean
    onSave?: () => void
    children?: React.ReactNode
    noEdit?: boolean
    tooltipText?: string
}

const JurisdictionAcctConfigWrapper: React.FC<
    JurisdictionAcctConfigWrapperProps
> = ({
    newSettingButtonText,
    validate,
    onSave,
    children,
    noEdit,
    tooltipText,
    isSubSettingEditing,
    isAddingSetting,
    setIsAddingSetting,
}) => {
    const [edit, setEdit] = React.useState(false)

    return (
        <div className=" pb-8">
            {edit ? null : null}
            <div>
                <div className="flex justify-between">
                    <div className="">
                        <div className="flex">
                            <h4 className="font-Inter pt-8 font-semibold">
                                Jurisdiction*
                            </h4>
                            {tooltipText && (
                                <Tooltip text={tooltipText}>
                                    <img
                                        src={InfoIcon}
                                        className="ml-2"
                                        alt="info icon"
                                    />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    {/* <ButtonClear
                        title="Close"
                        className="ml-6 !items-start  underline"
                        onClick={() => {
                            setEdit(false)
                            setIsAddingSetting(false)
                        }}
                    /> */}
                </div>
                <div className="mt-3">{children}</div>
                {!isSubSettingEditing && !isAddingSetting && (
                    <ButtonClear
                        title={'Add State'}
                        className=" mt-5 border-[2px] border-Red p-0 px-9 py-[14px]"
                        onClick={() => {
                            setEdit(true)
                            setIsAddingSetting(true)
                        }}
                    />
                )}
                {!isSubSettingEditing && isAddingSetting && (
                    <Button
                        title={'Save'}
                        className=" mt-5 p-0 px-9 py-[14px]"
                        onClick={() => {
                            let validated = true
                            if (validate) validated = validate()
                            if (!validated) return
                            if (onSave) onSave()
                            setEdit(false)
                            setIsAddingSetting(false)
                        }}
                    />
                )}
            </div>
        </div>
    )
}

export default JurisdictionAcctConfigWrapper
