import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { AuthProvider } from './components/AuthContext'
import { FirestoreListenerProvider } from './components/FirestoreListenerContext'
import { UserProvider } from './components/UserContext'
import { stripe_publishable_key } from './config'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const stripePromise = loadStripe(stripe_publishable_key)
root.render(
    <BrowserRouter>
        <AuthProvider>
            <FirestoreListenerProvider>
                <UserProvider>
                    <Elements stripe={stripePromise}>
                        <App />
                    </Elements>
                </UserProvider>
            </FirestoreListenerProvider>
        </AuthProvider>
    </BrowserRouter>
)
