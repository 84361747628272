import { useState } from 'react'
import { NavLink } from 'react-router-dom'

interface NavRowProfileProps {
    img: string
    title: string
    to: string
    open: boolean
}

function NavRowProfile({ img, title, to, open }: NavRowProfileProps) {
    const [active, setActive] = useState(false)
    return (
        <NavLink
            to={to}
            className={({ isActive }) => {
                setActive(isActive)
                return `flex origin-left transition-all duration-300 ease-in-out ${open ? ' ' : ' scale-x-[2.7397260274]'} `
            }}
        >
            <div
                className={` flex w-full border-t-[.1px] border-textGrey200 bg-Navy py-8  pl-5 hover:bg-bgSelected ${active ? 'text-white' : 'text-textGrey200 hover:text-white'}`}
            >
                <div className={`flex h-12 w-16 px-4 py-2`}>
                    <img
                        src={img}
                        className=" rounded-full"
                        alt="profile owner"
                    />
                </div>

                {open && (
                    <span
                        className={` base18 ml-1.5 py-2 font-bold hover:text-white `}
                    >
                        {title}
                    </span>
                )}
            </div>
        </NavLink>
    )
}

export default NavRowProfile
