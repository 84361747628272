import React, { useEffect, useRef, useState } from 'react'
import { httpsCallable } from 'firebase/functions'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'

import { useUser } from '../../../components/UserContext'
import { functions } from '../../../config'
import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import Loading from '../../../components/Loading'
import ButtonClear from '../../../components/ButtonClear'
import { Link } from 'react-router-dom'

interface UpdateCardProps {
    className?: string
    buttonText: string
}

const UpdateCard: React.FC<UpdateCardProps> = ({ buttonText, className }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const intervalRef = useRef<NodeJS.Timeout | null>(null)

    const stripe = useStripe()
    const elements = useElements()
    const link_card_info = httpsCallable(functions, 'payments-link_card_info')

    const { userData } = useUser()

    useEffect(() => {
        link_card_info({ warm_up: true })
        intervalRef.current = setInterval(
            async () => {
                try {
                    await link_card_info({ warm_up: true })
                } catch (error) {
                    console.error('Error in interval:', error)
                }
            },
            15 * 60 * 1000
        )
        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current)
            }
        }
    }, [])

    const handleCardSubmission = async () => {
        setLoading(true)

        if (!stripe || !elements) {
            return
        }
        const cardElement = elements.getElement(CardElement)

        if (!cardElement) {
            alert('It seems there is an issue with your card details.')
            setLoading(false)
            return
        }

        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        })

        if (error) {
            console.error('Failed to create payment method:', error)
            alert('Payment failed: ' + error.message)
            setLoading(false)
            return
        }

        if (paymentMethod) {
            try {
                await link_card_info({
                    payment_method: paymentMethod,
                    lawyer_id: userData!.id,
                })
            } catch (error) {
                alert('failed to add card: ' + error)
                setLoading(false)
            }
        }
        setLoading(false)
        setModalOpen(false)
    }
    return (
        <div>
            <Button
                title={buttonText}
                className={`${className} mt-6 px-9 py-3`}
                onClick={() => {
                    setModalOpen(!modalOpen)
                }}
            />
            <Modal visible={modalOpen}>
                <div className="w-[500px]">
                    <div className="mb-2 flex rounded-t-[16px] bg-bgGrey p-6">
                        <span className="ml-2 text-[24px] font-semibold text-Navy">
                            Add New Card Info
                        </span>
                    </div>
                    <div className="rounded-b-[16px] px-6 pb-6">
                        <p className="mt-10">Enter Card Info:</p>
                        <div className=" mb-8 mt-2 h-10 items-center rounded-[12px] bg-bgGrey px-3 py-3 ">
                            <CardElement className="text-textGrey200" />
                        </div>
                        <p>
                            Cards will be charged a 3% fee. Clients are charged
                            on the 15th and 29th of each month. Card payments
                            are processed by Stripe and we do not share your
                            data.{' '}
                            <Link
                                to="/privacy"
                                className="cursor text-Red underline"
                            >
                                Privacy Policy.
                            </Link>
                        </p>
                        {loading ? (
                            <div className="mt-8 flex h-full w-full items-center justify-center">
                                <Loading />
                            </div>
                        ) : (
                            <div className="mt-8 flex justify-end">
                                <ButtonClear
                                    title="Cancel"
                                    onClick={() => {
                                        setModalOpen(!modalOpen)
                                    }}
                                    className="px-9 py-[18px] text-textGrey200"
                                />
                                <Button
                                    title={buttonText}
                                    className="px-9 py-[18px]"
                                    onClick={() => {
                                        handleCardSubmission()
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default UpdateCard
