import React, { ReactNode } from 'react'

interface ModalProps {
    visible: boolean
    children: ReactNode
    onClose?: () => void
}

const Modal: React.FC<ModalProps> = ({ visible, children, onClose }) => {
    if (!visible) {
        return null
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-Navy bg-opacity-50">
            <div className="rounded-[16px] bg-white shadow-xl">{children}</div>
        </div>
    )
}

export default Modal
