import React from 'react'

import Tooltip from '../Tooltip'
import InfoIcon from '../../assets/images/InfoIcon.svg'

interface TextInputProps {
    className?: string
    tooltipText?: string
    title?: string
    placeholder?: string
    value: string
    setter: React.Dispatch<React.SetStateAction<any>>
    type?: string
}

const ProfileTextInput: React.FC<TextInputProps> = ({
    className,
    tooltipText,
    title,
    placeholder,
    value,
    type,
    setter,
}) => {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setter(event.target.value)
    }

    return (
        <div className="mt-4">
            {title && (
                <div className="flex ">
                    <span className="base16 mb-2 font-semibold text-Navy">
                        {title}
                    </span>
                    {tooltipText && (
                        <Tooltip text={tooltipText}>
                            <img
                                src={InfoIcon}
                                className="ml-2"
                                alt="info icon"
                            />
                        </Tooltip>
                    )}
                </div>
            )}
            <input
                type={type ?? 'text'}
                value={value}
                placeholder={placeholder ?? title ?? ''}
                onChange={handleInputChange}
                className={
                    className +
                    ' base16 rounded-[12px] border-[1px] border-borderGrey px-3 py-[14px] focus:border-[2px] focus:border-textGrey100 focus:outline-none'
                }
            />
        </div>
    )
}

export default ProfileTextInput
