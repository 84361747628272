import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions } from 'firebase/functions'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const isProduction =
    process.env.REACT_APP_GCP_PROJECT &&
    process.env.REACT_APP_GCP_PROJECT === 'firmleads-production'

// Set different environment variables based on the environment
export const firebaseConfig = isProduction
    ? {
          apiKey: process.env.REACT_APP_APIKEY,
          authDomain: process.env.REACT_APP_AUTHDOMAIN,
          databaseURL: process.env.REACT_APP_DB,
          projectId: process.env.REACT_APP_PID,
          storageBucket: process.env.REACT_APP_SB,
          messagingSenderId: process.env.REACT_APP_SID,
          appId: process.env.REACT_APP_APPID,
          measurementId: process.env.REACT_APP_MID,
      }
    : {
          apiKey: process.env.REACT_APP_APIKEY_DEV,
          authDomain: process.env.REACT_APP_AUTHDOMAIN_DEV,
          databaseURL: process.env.REACT_APP_DB_DEV,
          projectId: process.env.REACT_APP_PID_DEV,
          storageBucket: process.env.REACT_APP_SB_DEV,
          messagingSenderId: process.env.REACT_APP_SID_DEV,
          appId: process.env.REACT_APP_APPID_DEV,
          measurementId: process.env.REACT_APP_MID_DEV,
      }

export const stripe_publishable_key = isProduction
    ? 'pk_live_51OO9zhBbf2BxpPqTnr4dinRhvdFvZrFKx2pN0xwx1miRqmcSLoyba0xp7znV3ivOiq9g0OZfjuaTdBlhAXSOp3Dn001JzA4BoP'
    : 'pk_test_51OO9zhBbf2BxpPqTVfTzk4KhOzVaQA5D8qJIdZ18i56oxAR32QM8WyZOokHb6eTKTntZxAXvkPyrVpZOVapWybHj00KXMbeE5g'

// console.log(firebaseConfig)
const app = initializeApp(firebaseConfig)

export const analytics = getAnalytics(app)
export const functions = getFunctions(app)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
