import { Timestamp } from 'firebase/firestore'
import moment from 'moment'
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber'
export function parsePhoneToReadable(phone: string) {
    const phoneUtil = PhoneNumberUtil.getInstance()

    try {
        const number = phoneUtil.parse(phone)

        const formattedNumber = phoneUtil.format(
            number,
            PhoneNumberFormat.NATIONAL
        )

        const country_code = number.getCountryCode()

        if (country_code === 1) {
            return formattedNumber
        }

        return '+' + country_code + ' ' + formattedNumber
    } catch (error) {
        return phone
    }
}

export function convert_to_e164(phone_number: string) {
    const cleaned_number = phone_number.replace(/\D/g, '')

    if (phone_number[0] === '+') {
        return '+' + cleaned_number
    }

    if (cleaned_number.length === 10) {
        return `+1${cleaned_number}`
    }

    return `+${cleaned_number}`
}

export function formatTimestamp(timestamp: Timestamp | null): string {
    if (timestamp && typeof timestamp.toDate === 'function') {
        return moment(timestamp.toDate()).format('MM/DD/YYYY hh:mm a')
    }
    return 'Updating...' // or any other placeholder text
}
