import { useEffect, useState } from 'react'
import {
    onSnapshot,
    query,
    collection,
    where,
    orderBy,
} from 'firebase/firestore'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import ContentArea from '../../components/ContentArea'
import { db } from '../../config'
import { useUser } from '../../components/UserContext'
import { useFirestoreListener } from '../../components/FirestoreListenerContext'
import TD from '../../components/TableData'
import TH from '../../components/TableHeader'
import TR from '../../components/TableRow'
import { parsePhoneToReadable } from '../../utils/phoneParsing'

function CreditRequests() {
    const [creditRequests, setCreditRequests] = useState([] as any[])

    const { userData } = useUser()
    const { addUnsubscribeFunction } = useFirestoreListener()
    const navigate = useNavigate()

    useEffect(() => {
        async function handleSearch() {
            try {
                let creditRequestsQuery

                if (
                    userData?.role === 'admin' ||
                    userData?.role === 'employee'
                ) {
                    creditRequestsQuery = query(
                        collection(db, 'credits'),
                        orderBy('created', 'desc')
                    )
                } else {
                    creditRequestsQuery = query(
                        collection(db, 'credits'),
                        where('lawyer_id', '==', userData!.id + ''),
                        orderBy('created', 'desc')
                    )
                }

                const unsubscribe = onSnapshot(
                    creditRequestsQuery,
                    (snapshot) => {
                        setCreditRequests(
                            snapshot.docs.map((creditRequest) => ({
                                id: creditRequest.id,
                                ref: creditRequest.ref,
                                ...creditRequest.data(),
                            }))
                        )
                    }
                )
                addUnsubscribeFunction(unsubscribe)
            } catch (error) {
                console.error('error searching creditRequests:', error)
            }
        }

        handleSearch()
    }, [])

    return (
        <ContentArea>
            <h1 className="mb-8">Credit Requests</h1>
            <div className="rounded-2xl bg-white">
                <table className="w-full">
                    <TR className="base16 text-left text-Navy">
                        {/* <TH >ID</TH> */}
                        <TH className="pl-6">Lead</TH>
                        {/* <TH>Lead Age</TH> */}

                        <TH>Reason</TH>
                        <TH>Created</TH>
                        <TH>Update</TH>
                        <TH>Status</TH>
                    </TR>
                    {creditRequests.length > 0 &&
                        creditRequests.map((creditRequest: any) => {
                            return (
                                <tr
                                    className="cursor-pointer"
                                    key={creditRequest.id}
                                    onClick={() => {
                                        navigate(`/credits/${creditRequest.id}`)
                                    }}
                                >
                                    {/* <TD className="pl-6 text-Red">
                                        {creditRequest.id.substring(0, 8)}
                                    </TD> */}
                                    <TD className="pl-6">
                                        {creditRequest.lead_name}
                                    </TD>
                                    {/* <TD>
                                        {moment().diff(
                                            moment(
                                                creditRequest.lead_created.toDate()
                                            ),
                                            'days'
                                        )}
                                    </TD> */}

                                    <TD>
                                        {parsePhoneToReadable(
                                            creditRequest.reason
                                        )}
                                    </TD>
                                    <TD>
                                        {moment(
                                            creditRequest.created.toDate()
                                        ).format('MM/DD/YYYY')}
                                    </TD>
                                    <TD className="text-Red">
                                        {moment(
                                            creditRequest.updated.toDate()
                                        ).format('MM/DD/YYYY hh:mm a')}
                                    </TD>
                                    <TD>
                                        {' '}
                                        <div
                                            className={
                                                'base14 h-full w-32 rounded-xl px-4 py-1 text-center font-bold capitalize ' +
                                                (creditRequest.status ===
                                                'approved'
                                                    ? ' bg-bgGreen text-Green'
                                                    : creditRequest.status ===
                                                        'not_approved'
                                                      ? ' bg-bgGrey text-textGrey200'
                                                      : 'bg-bgSecondColor text-SecondColor')
                                            }
                                        >
                                            {creditRequest.status.replace(
                                                '_',
                                                ' '
                                            )}
                                        </div>
                                    </TD>
                                </tr>
                            )
                        })}
                </table>
            </div>
        </ContentArea>
    )
}

export default CreditRequests
