import React, { ReactNode, useState } from 'react'
import TD from '../TableData'

interface TRProps {
    children: ReactNode
    className?: string
    issue?: string
    jurisdiction?: string
}

const TR: React.FC<TRProps> = ({
    children,
    className,
    issue,
    jurisdiction,
}) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <tr
                className={className + ' '}
                onClick={() => {
                    setOpen(!open)
                }}
            >
                {children}
            </tr>
            {open && (issue || jurisdiction) && (
                <tr className="bg-trGrey">
                    <TD colSpan={8} className="pl-6 text-textGrey200">
                        <p className="text-textGrey200">
                            <span className="font-bold">Jurisdiction:</span>{' '}
                            {jurisdiction ?? ''}
                        </p>
                        <span className="font-bold">Issue:</span> {issue ?? ''}
                    </TD>
                </tr>
            )}
        </>
    )
}

export default TR
