import React, { useState, useCallback, useEffect, useRef } from 'react'
import { httpsCallable } from 'firebase/functions'
import {
    collection,
    doc,
    onSnapshot,
    updateDoc,
    getDoc,
} from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'

import { UserDataType, useUser } from '../../components/UserContext'
import ProfileTextInput from '../../components/ProfileTextInput'
import PracticeAreaSelector from '../../components/PracticeAreaSelector'
import { parsePracticeAreas } from '../../utils/practiceAreas'
import UpdateBank from '../Billing/UpdateBank'
import UpdateCard from '../Billing/UpdateCard'
import { db, functions } from '../../config'
import { useFirestoreListener } from '../../components/FirestoreListenerContext'
import Loading from '../../components/Loading'
import ButtonClear from '../../components/ButtonClear'
import Button from '../../components/Button'
import Logo from '../../assets/images/LoginLogo.svg'
import { useAuth } from '../../components/AuthContext'
import ProfilePhoneInput from '../../components/ProfilePhoneInput'
import { convert_to_e164, parsePhoneToReadable } from '../../utils/phoneParsing'
import AddJurisdiction from '../../components/JurisdictionSelector/AddJurisdiction'
import SettingsGroupSubSetting from '../../components/SettingGroupSubSetting'
import SetStateJurisdiction from '../../components/JurisdictionSelector/SetStateJurisdiction'
import JurisdictionAcctConfigWrapper from './JurisdictionAcctConfigWrapper'

const AccountConfig: React.FC = () => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [phoneNotif, setPhoneNotif] = useState('')
    const [firmName, setFirmName] = useState('')
    const [street, setStreet] = useState('')
    const [apartment, setApartment] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [postcode, setPostcode] = useState('')
    const [practice_areas, setPracticeAreas] = useState<string[]>([])
    const [jurisdiction, setJurisdiction] = useState<{
        [key: string]: { entire_state: boolean; counties: string[] }
    }>({})
    const [jurisdictionBeingAdded, setJurisdictionBeingAdded] = useState<{
        [key: string]: { entire_state: boolean; counties: string[] }
    }>({})
    const [linkToken, setLinkToken] = useState(null)
    const [agree, setAgree] = useState(false)
    const [selectedOption, setSelectedOption] = useState<string>('bank')
    const [billing, setBilling] = useState<null | any>(null)
    const [loading, setLoading] = useState(false)
    const intervalRef = useRef<NodeJS.Timeout | null>(null)
    const [isSubSettingEditing, setIsSubSettingEditing] = useState(false)
    const [isAddingSetting, setIsAddingSetting] = useState(false)

    const [jurisdictionError, setJurisdictionError] = useState<string>('')
    const [isJurisdictionValid, setIsJurisdictionValid] = useState(false)

    const { userData, setUserData } = useUser()

    const { addUnsubscribeFunction } = useFirestoreListener()
    const navigate = useNavigate()
    const set_configured = httpsCallable(functions, 'mgmt-set_configured')
    const { refreshIdToken } = useAuth()!

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value)
    }

    const create_link_token = httpsCallable(
        functions,
        'payments-create_link_token'
    )

    const generateToken = useCallback(async () => {
        try {
            const res: any = await create_link_token({
                lawyer_id: userData?.id,
                lawyer_name: userData?.email,
            })

            setLinkToken(res.data.link_token)
        } catch (error) {
            console.error('create_link_token', error)
        }
    }, [setLinkToken])

    useEffect(() => {
        if (linkToken !== null) {
            return
        }

        generateToken()
    }, [linkToken])

    useEffect(() => {
        const docRef = doc(db, 'billing', userData!.id)

        const checkDocumentExistence = async () => {
            let docSnap
            try {
                docSnap = await getDoc(docRef)
            } catch (error) {
                return false
            }
            return docSnap.exists()
        }

        const setUpOnSnapshot = () => {
            return onSnapshot(docRef, (snapshot) => {
                setBilling({
                    id: snapshot.id,
                    ...snapshot.data(),
                })
            })
        }

        const interval = setInterval(async () => {
            const exists = await checkDocumentExistence()

            if (exists) {
                clearInterval(interval)
                const unsubscribe = setUpOnSnapshot()
                addUnsubscribeFunction(unsubscribe)
            }
        }, 150)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        set_configured({ warm_up: true })
        intervalRef.current = setInterval(
            async () => {
                try {
                    await set_configured({ warm_up: true })
                } catch (error) {
                    console.error('Error in interval:', error)
                }
            },
            15 * 60 * 1000
        )

        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current)
            }
        }
    }, [])

    const clearTheInterval = (): void => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
        }
    }

    function validateJurisdiction(jurisdiction: {
        [key: string]: { entire_state: boolean; counties: string[] }
    }): boolean {
        if (Object.keys(jurisdiction).length === 0) {
            setJurisdictionError('Please add a state to your jurisdiction.')
            setIsJurisdictionValid(false)
            return false
        }
        for (const key in jurisdiction) {
            if (jurisdiction.hasOwnProperty(key)) {
                const field = jurisdiction[key]
                console.log('field', field)
                if (!field.entire_state && field.counties.length === 0) {
                    setJurisdictionError(
                        'Please select entire state or list the counties in your jurisdiction.'
                    )
                    setIsJurisdictionValid(false)
                    return false
                }
            }
        }
        setJurisdictionError('')
        setIsJurisdictionValid(true)
        return true
    }

    const isReady = () => {
        return (
            billing &&
            ((billing.card && billing.card.last4 !== '') ||
                (billing.bank_accounts && billing.bank_accounts.length > 0)) &&
            agree &&
            firstName.length >= 2 &&
            lastName.length >= 2 &&
            phone.length > 7 &&
            firmName.length >= 2 &&
            street.length > 2 &&
            city.length >= 2 &&
            state.length >= 2 &&
            postcode.length >= 5 &&
            practice_areas.length > 0 &&
            isJurisdictionValid
        )
    }

    const removeStateJurisdiction = async (state: string) => {
        let newJurisdiction = { ...jurisdiction }
        delete newJurisdiction[state]
        setJurisdiction(newJurisdiction)
    }

    const handleSubmit = async () => {
        try {
            setLoading(true)
            const docRef = doc(collection(db, 'lawyers'), userData!.id)

            await updateDoc(docRef, {
                first_name: firstName,
                last_name: lastName,
                // lastName: 'test',
                phone: '+' + phone.replace(/\D/g, ''),
                firm_name: firmName,
                firm_address: {
                    street,
                    apartment,
                    city,
                    state,
                    postcode,
                    display:
                        street +
                        ' ' +
                        apartment +
                        ', ' +
                        city +
                        ', ' +
                        state +
                        ' ' +
                        postcode,
                    coordinates: '',
                },
                practice_areas,
                jurisdiction,
                notifications: {
                    receive_text: false,
                    text: '+' + phoneNotif.replace(/\D/g, ''),
                    receive_email: false,
                    email: '',
                },
            })
            const data = (await getDoc(docRef)).data()!
            await set_configured({
                name: firstName + ' ' + lastName,
                email: data.email,
            })

            clearTheInterval()
        } catch (error) {
            setLoading(false)
            console.error('Failed to update information', error)
        } finally {
            setUserData({
                ...userData,
                configured: true,
                name: firstName + ' ' + lastName,
                role: 'client',
            } as UserDataType)
            refreshIdToken()
            navigate('/leads')
            setLoading(false)
        }
    }

    if (!billing || !billing.card || loading) {
        return (
            <div className="flex h-screen items-center justify-center">
                <Loading />
            </div>
        )
    }

    console.log('jurisdiction', jurisdiction)

    return (
        <div className="bg-bgGrey pb-[100px]">
            <div className="flex justify-center pb-8 pt-12">
                <img src={Logo} className="w-[180px]" alt="logo" />
            </div>
            <div className="mb-25 mx-auto w-[570px] rounded-[16px] bg-white p-6">
                <div>
                    <h4 className="font-Inter mb-8 font-semibold">
                        Create Your Account
                    </h4>
                    <ProfileTextInput
                        className="w-full"
                        title="First Name*"
                        value={firstName}
                        setter={setFirstName}
                    />
                    <ProfileTextInput
                        className="w-full"
                        title="Last Name*"
                        value={lastName}
                        setter={setLastName}
                    />
                    <ProfilePhoneInput
                        className="w-full"
                        title="Phone*"
                        value={parsePhoneToReadable(phone)}
                        setter={(value: string) => {
                            setPhone(convert_to_e164(value))
                        }}
                    />
                    <ProfilePhoneInput
                        className="w-full"
                        title="Phone For Notifications"
                        value={parsePhoneToReadable(phoneNotif)}
                        setter={(value: string) => {
                            setPhoneNotif(convert_to_e164(value))
                        }}
                    />
                    <h4 className="font-Inter py-8 font-semibold">
                        Firm Information
                    </h4>
                    <ProfileTextInput
                        className="w-full"
                        title="Firm Name*"
                        value={firmName}
                        setter={setFirmName}
                    />
                    <ProfileTextInput
                        className="w-full"
                        title="Street*"
                        value={street}
                        setter={setStreet}
                    />
                    <ProfileTextInput
                        className="w-full"
                        title="Apartment"
                        value={apartment}
                        setter={setApartment}
                    />
                    <ProfileTextInput
                        className="w-full"
                        title="City*"
                        value={city}
                        setter={setCity}
                    />
                    <div className="flex justify-between border-b-[1px] border-b-borderGrey pb-8">
                        <ProfileTextInput
                            className="w-full"
                            title="State*"
                            value={state}
                            setter={setState}
                        />
                        <ProfileTextInput
                            className="w-full"
                            title="Postcode*"
                            value={postcode}
                            setter={setPostcode}
                        />
                    </div>
                    <h4 className="font-Inter py-8 font-semibold">
                        Practice Areas*
                    </h4>

                    <PracticeAreaSelector
                        displayAreas={parsePracticeAreas(practice_areas)}
                        selectedAreas={practice_areas}
                        setSelectedAreas={setPracticeAreas}
                    />

                    <JurisdictionAcctConfigWrapper
                        newSettingButtonText="Add State"
                        isSubSettingEditing={isSubSettingEditing}
                        isAddingSetting={isAddingSetting}
                        setIsAddingSetting={setIsAddingSetting}
                        validate={() => {
                            return validateJurisdiction(jurisdictionBeingAdded)
                        }}
                        onSave={() => {
                            setJurisdiction({
                                ...jurisdictionBeingAdded,
                                ...jurisdiction,
                            })
                        }}
                    >
                        {Object.entries(jurisdiction).map(([state, data]) => (
                            <SettingsGroupSubSetting
                                title={state}
                                value={
                                    data.entire_state
                                        ? 'Entire state'
                                        : 'Select counties'
                                }
                                setSubSettingEditing={setIsSubSettingEditing}
                                noEdit={isAddingSetting || isSubSettingEditing}
                                onRemove={() => {
                                    removeStateJurisdiction('')
                                }}
                                validate={() => {
                                    return validateJurisdiction(jurisdiction)
                                }}
                                key={state}
                            >
                                <SetStateJurisdiction
                                    state={state}
                                    state_data={data}
                                    jurisdiction={jurisdiction}
                                    setJurisdiction={() => {
                                        console.log(setJurisdictionBeingAdded)
                                        console.log('in set jurisdiction')
                                    }}
                                />
                            </SettingsGroupSubSetting>
                        ))}
                        {isAddingSetting && (
                            <AddJurisdiction
                                jurisdiction={jurisdiction}
                                setJurisdiction={setJurisdictionBeingAdded}
                                setIsAddingSetting={setIsAddingSetting}
                            />
                        )}
                    </JurisdictionAcctConfigWrapper>
                    {jurisdictionError !== '' && (
                        <p className="text-[#CF0909]">{jurisdictionError}</p>
                    )}
                    <h4 className="font-Inter border-t-[1px] border-t-borderGrey pb-8 pt-8 font-semibold">
                        Enter Billing Information
                    </h4>
                    <div>
                        <span className="base16 font-semibold text-Navy">
                            Billing Method*
                        </span>
                        <form className="my-4">
                            <label className="mr-10">
                                <input
                                    type="radio"
                                    name="Bank"
                                    value="bank"
                                    checked={selectedOption === 'bank'}
                                    onChange={handleChange}
                                    className="mr-1"
                                />
                                Bank
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="Card"
                                    value="card"
                                    checked={selectedOption === 'card'}
                                    onChange={handleChange}
                                    className="mr-1"
                                />
                                Card
                            </label>
                        </form>
                        {selectedOption === 'card' &&
                            (billing.card.last4 !== '' ? (
                                <p className="t-4 text-Green underline">
                                    Card Linked!
                                </p>
                            ) : (
                                <UpdateCard buttonText="Link Card" />
                            ))}
                        {selectedOption === 'bank' &&
                            (billing.bank_accounts.length > 0 ? (
                                <p className="text-Green underline">
                                    Bank Linked!
                                </p>
                            ) : (
                                <UpdateBank
                                    linkToken={linkToken}
                                    buttonText="Link Bank"
                                />
                            ))}
                    </div>
                    <div className="flex py-8">
                        <input
                            className="mr-2"
                            type="checkbox"
                            checked={agree}
                            onChange={(e: any) => {
                                setAgree(e.target.checked)
                            }}
                        />
                        <p>
                            I agree to the{' '}
                            <a
                                className="text-Red"
                                href="https://firmleads.io/terms"
                            >
                                Terms of Service
                            </a>{' '}
                            and have read the{' '}
                            <a
                                className="text-Red"
                                href="https://firmleads.io/privacy"
                            >
                                Privacy Policy
                            </a>
                        </p>
                    </div>
                    {isReady() ? (
                        <Button
                            title="Submit"
                            onClick={() => handleSubmit()}
                            className="w-full bg-Red py-[18px] "
                        />
                    ) : (
                        <ButtonClear
                            title="Submit"
                            onClick={() => {}}
                            disabled={true}
                            className="w-full bg-bgGrey py-[18px] text-textGrey200"
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default AccountConfig
