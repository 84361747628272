import { useState } from 'react'
import { collection, Timestamp, addDoc, updateDoc, DocumentReference, DocumentData } from 'firebase/firestore'

import Button from '../../../components/Button'
import Modal from '../../../components/Modal'
import NoteIcon from '../../../assets/images/NoteIcon.svg'
import { db } from '../../../config'
import ButtonClear from '../../../components/ButtonClear'
import Loading from '../../../components/Loading'
import Dropdown from '../../../components/Dropdown'

interface RequestCreditModalProps {
    lead_ref: DocumentReference<DocumentData>
    lawyer_id: string
    lead_created: Timestamp
    lead_name: string
    lead_charge_category: string
}

function RequestCreditModal({
    lead_ref,
    lead_created,
    lead_name,
    lead_charge_category,
    lawyer_id,
}: RequestCreditModalProps) {
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reason, setReason] = useState('')
    const [note, setNote] = useState('')
    const characterLimit = 650


    const handleNoteChange = (e: any) => {
        const text = e.target.value
        if (text.length <= characterLimit) {
            setNote(text)
        }
    }

    const handleSubmit = async () => {
        setLoading(true)
        try {

            await addDoc(collection(db, 'credits'), {
                lawyer_id,
                lead_id: lead_ref.id,
                detail: note,
                lead_created,
                lead_name,
                lead_charge_category,
                created: Timestamp.now(),
                updated: Timestamp.now(),
                staff_id: '',
                reason,
                status: 'pending',
            })

            await updateDoc(lead_ref, {
                credit_requested: true,
            })

        } catch (error) {
            console.log('error requesting credit:', error)
        } finally {
            setModalOpen(false)
            setNote('')
        }
    }
    return (
        <div>
            {loading && !modalOpen ? (
                <Loading />
            ) : (
                <ButtonClear
                    title="Request Credit"
                    className="bg-bgGrey px-9 py-[18px] text-textGrey200"
                    onClick={() => {
                        setModalOpen(!modalOpen)
                    }}
                />
            )}
            <Modal visible={modalOpen}>
                <div className="w-[500px]">
                    <div className="mb-2 flex rounded-t-[16px] bg-bgGrey p-6">
                        <img src={NoteIcon} className="h-5" alt="note icon" />
                        <span className="ml-2 text-[24px] font-semibold text-Navy">
                            New Credit Request
                        </span>
                    </div>
                    <div className="rounded-b-[16px] px-6 pb-6">
                        <p className="base16 mb-2 mt-5 font-semibold">Reason</p>
                        <Dropdown
                            options={[
                                'No phone number',
                                'Wrong jurisdiction',
                                'Duplicate',
                                'Other',
                            ]}
                            selected={reason}
                            setSelected={setReason}
                            placeholder="Select.."
                        />
                        <p className="base16 mb-2 mt-5 font-semibold">
                            Comment
                        </p>
                        <textarea
                            value={note}
                            rows={5}
                            placeholder="Enter comment..."
                            onChange={handleNoteChange}
                            className=" w-full rounded-[12px] border-[1px] border-borderGrey p-3 text-Navy focus:border-[2px] focus:border-textGrey100 focus:outline-none"
                            maxLength={650}
                        />
                        <p className="my-2 font-semibold text-Navy">
                            {characterLimit - note.length} Characters Remaining
                        </p>

                        {loading ? (
                            <div className="mt-8 flex h-full w-full items-center justify-center">
                                <Loading />
                            </div>
                        ) : (
                            <div className="mt-8 flex justify-end">
                                <ButtonClear
                                    title="Cancel"
                                    onClick={() => {
                                        setModalOpen(!modalOpen)
                                    }}
                                    className="px-9 py-[18px] text-textGrey200"
                                />
                                <Button
                                    title="Request Credit"
                                    className="px-9 py-[18px]"
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default RequestCreditModal
