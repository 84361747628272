import React, { useEffect, useRef, useState } from 'react'
import { states_long_to_short } from '../../utils/leadMaps'
// import PinDrop from '../../assets/images/location.svg'

interface CountySuggestProps {
    state: string
    selectedCounties: string[]
    clearOnStateChange: boolean
    setSelectedCounties: (value: string[]) => void
}

const CountySuggest: React.FC<CountySuggestProps> = ({
    state,
    selectedCounties,
    clearOnStateChange,
    setSelectedCounties,
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [suggestions, setSuggestions] = useState<string[]>([])
    const [showSuggestions, setShowSuggestions] = useState(false)
    const componentRef = useRef<HTMLDivElement | null>(null)

    let autocomplete: any

    useEffect(() => {
        if (clearOnStateChange) {
            setSelectedCounties([])
        }
    }, [state, clearOnStateChange])

    useEffect(() => {
        const setupAutocomplete = () => {
            if (window.google && autocomplete === undefined) {
                autocomplete =
                    new window.google.maps.places.AutocompleteService()
                inputRef.current?.addEventListener('input', handleInputChange)
            }
        }

        setupAutocomplete()

        return () => {
            inputRef.current?.removeEventListener('input', handleInputChange)
        }
    })

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                componentRef.current &&
                !componentRef.current.contains(event.target as Node)
            ) {
                setShowSuggestions(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleInputChange = () => {
        let input = ''
        if (inputRef.current?.value !== '') {
            input =
                inputRef.current?.value +
                    ' county, ' +
                    states_long_to_short[state] ?? ''
        }

        const service = new window.google.maps.places.AutocompleteService()
        const request = {
            input,
            types: ['(regions)'],
            componentRestrictions: {
                country: 'US',
            },
        }

        service.getPlacePredictions(
            request,
            (predictions: any, status: any) => {
                if (
                    status === window.google.maps.places.PlacesServiceStatus.OK
                ) {
                    const counties =
                        predictions
                            ?.filter((prediction: any) =>
                                prediction.types.includes(
                                    'administrative_area_level_2'
                                )
                            )
                            .map((prediction: any) => prediction.description) ||
                        []
                    setSuggestions(counties)
                    setShowSuggestions(true)
                }
            }
        )
    }

    const handleSuggestionClick = (suggestion: string) => {
        setSelectedCounties([...selectedCounties, suggestion])
        setSuggestions([])
        setShowSuggestions(false)
        if (inputRef.current) {
            inputRef.current.value = ''
        }
    }

    const handleRemoveCounty = (county: string) => {
        setSelectedCounties(selectedCounties.filter((c) => c !== county))
    }
    return (
        <div ref={componentRef}>
            <div className="max-w-xs">
                <div className="border-MainColor focus-within:border-Neutral400 focus-within:outline-MainColor flex items-center rounded-[12px] border-2 p-[8px] focus-within:outline focus-within:outline-2 focus-within:outline-offset-2 md:focus-within:outline-none">
                    {/* <img
                    src={PinDrop}
                    className="mr-[8px] h-[24px]"
                    alt="pin drop icon"
                /> */}
                    <input
                        ref={inputRef}
                        type="text"
                        onChange={handleInputChange}
                        placeholder="Type a county name..."
                        className="w-full text-[17px] focus:outline-none md:text-[18px] "
                    />
                </div>

                {showSuggestions && suggestions.length > 0 && (
                    <div className="mx-[12px] rounded-[16px] pb-[8px] shadow-md ">
                        {suggestions.map((suggestion, index) => (
                            <div
                                className="border-Neutral300 border-t-[1px] py-[5px] pl-[30px] pr-[8px] "
                                key={index}
                                onClick={() =>
                                    handleSuggestionClick(suggestion)
                                }
                            >
                                {suggestion}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="mt-8">
                <ul>
                    {selectedCounties.map((county, index) => (
                        <li key={index} className="flex justify-between ">
                            <p>{county}</p>
                            <button
                                onClick={() => handleRemoveCounty(county)}
                                className="mr-[225px]"
                            >
                                x
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default CountySuggest
