import { useState, ReactElement } from 'react'
import { NavLink } from 'react-router-dom'

interface NavRowProps {
    icon: ReactElement
    title: string
    to: string
    open: boolean
    lowerRow?: boolean
    onClick?: () => void
}

function NavRow({ icon, title, to, open, lowerRow, onClick }: NavRowProps) {
    const [active, setActive] = useState(false)
    return (
        <NavLink
            to={to}
            className={({ isActive }) => {
                setActive(isActive)
                return `origin-left  pl-5 transition-all duration-300 ease-in-out ${open ? ' ' : ' scale-x-[2.7397260274]'} `
            }}
            onClick={onClick}
        >
            {lowerRow ? (
                <div
                    className={`flex ${active ? 'text-white' : (lowerRow ? 'text-textGrey200' : 'text-White') + ' hover:text-white'}`}
                >
                    <div
                        className={`flex h-10 w-14 items-center justify-center px-4 py-2 ${active ? 'rounded-lg bg-bgSelected' : ''}`}
                    >
                        {icon}
                    </div>

                    {open && (
                        <span className={`base18 ml-3 py-2 font-bold `}>
                            {title}
                        </span>
                    )}
                </div>
            ) : (
                <div
                    className={`flex  ${active ? 'text-white' : 'text-textGrey200 hover:text-white'}`}
                >
                    <div
                        className={`flex h-10 w-14 items-center justify-center px-4 py-2 ${active ? 'rounded-lg bg-bgSelected' : ''}`}
                    >
                        {icon}
                    </div>

                    {open && (
                        <span
                            className={`base18 ml-3 py-2 font-bold text-white`}
                        >
                            {title}
                        </span>
                    )}
                </div>
            )}
        </NavLink>
    )
}

export default NavRow
