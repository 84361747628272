import React, {
    useContext,
    useState,
    useEffect,
    ReactNode,
    ContextType,
} from 'react'
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    Unsubscribe,
    User,
    UserCredential,
    updateEmail,
    updatePassword,
} from 'firebase/auth'

import { auth } from '../../config'

export interface AuthContextValue {
    currentUser?: User | null
    login: (email: string, password: string) => Promise<UserCredential>
    signup: (email: string, password: string) => Promise<UserCredential>
    logout: () => Promise<void>
    resetPassword: (email: string) => Promise<void>
    updateUserEmail: (email: string) => Promise<void>
    updateUserPassword: (password: string) => Promise<void>
    refreshIdToken: () => Promise<string>
}

const AuthContext = React.createContext<AuthContextValue | null>(null)

export function useAuth(): ContextType<typeof AuthContext> {
    return useContext(AuthContext)
}

interface Props {
    children: ReactNode
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null)
    const [loading, setLoading] = useState(true)

    function signup(email: string, password: string) {
        return createUserWithEmailAndPassword(auth, email, password)
    }

    function login(email: string, password: string) {
        return signInWithEmailAndPassword(auth, email, password)
    }

    function logout() {
        return auth.signOut()
    }

    function resetPassword(email: string) {
        return sendPasswordResetEmail(auth, email)
    }

    function updateUserEmail(email: string): Promise<void> {
        if (currentUser !== null) return updateEmail(currentUser, email)
        return new Promise<void>(() => {})
    }

    function updateUserPassword(password: string): Promise<void> {
        if (currentUser !== null) return updatePassword(currentUser, password)
        return new Promise<void>(() => {})
    }

    const refreshIdToken = async (): Promise<string> => {
        if (currentUser) {
            return currentUser.getIdToken(true)
        } else {
            throw new Error('User is not authenticated')
        }
    }

    useEffect(() => {
        const unsubscribe: Unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user)
            setLoading(false)
        })
        return () => unsubscribe()
    }, [])

    const value = {
        currentUser,
        login,
        signup,
        logout,
        resetPassword,
        updateUserEmail,
        updateUserPassword,
        refreshIdToken,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
