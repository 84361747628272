import React, { useState } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

import { auth } from '../../config'
import ContentDetail from '../../components/ContentDetail'
import LoginLogo from '../../assets/images/LoginLogo.svg'
import EmailIcon from '../../assets/images/EmailIcon.svg'
import PasswordIcon from '../../assets/images/PasswordIcon.svg'
import BackArrow from '../../assets/images/BackArrow.svg'
import { Link } from 'react-router-dom'
import Checkbox from '../../components/Radio'
import Button from '../../components/Button'
import ButtonClear from '../../components/ButtonClear'
import Loading from '../../components/Loading'
import EyeIcon from '../../assets/images/EyeIconPassword.svg'

function SignIn() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [rememberMe, setRememberMe] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const navigate = useNavigate()

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRememberMe(!rememberMe)
    }

    const signIn = async () => {
        setLoading(true)
        try {
            await signInWithEmailAndPassword(auth, email, password)
        } catch (error: any) {
            console.log(error)
            console.error('Sign in failed: ' + error.message)
            setLoading(false)
        } finally {
            navigate('/leads')
            setLoading(false)
        }
    }
    return (
        <div className="h-screen w-screen bg-bgGrey">
            <a href="https://www.firmleads.io" className="mt-6">
                <img className="p-6" src={BackArrow} alt="Back Arrow" />
            </a>
            <div className="flex items-center justify-center">
                <div>
                    <ContentDetail className=" h-[430px] w-[335px]">
                        <div className="mb-9 flex justify-center">
                            <img src={LoginLogo} alt="login logo" />
                        </div>
                        <div className="mb-3 flex w-full items-center rounded-[12px] border-[1px] border-borderGrey px-3 py-[14px] focus-within:border-2 focus-within:border-textGrey100">
                            <img src={EmailIcon} alt="email icon" />
                            <input
                                placeholder="Email"
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                className="ml-[8px] w-full cursor-pointer border-l-[1px] border-borderGrey pl-3 outline-none"
                            />
                        </div>
                        <div className="mb-3 flex w-full items-center rounded-[12px] border-[1px] border-borderGrey px-3 py-[14px] focus-within:border-2 focus-within:border-textGrey100">
                            <img src={PasswordIcon} alt="password icon" />
                            <input
                                placeholder="Password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                                className="ml-[8px] w-full cursor-pointer border-l-[1px] border-borderGrey pl-3 outline-none"
                            />
                            <img
                                src={EyeIcon}
                                alt="eye icon"
                                className="w-[20px] cursor-pointer"
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        </div>
                        <div className="mb-6 flex justify-between">
                            <div>
                                <Checkbox
                                    isChecked={rememberMe}
                                    handleChange={handleOptionChange}
                                />
                                <span className="ml-[8px] text-textGrey200">
                                    Remember me
                                </span>
                            </div>
                            <div>
                                <Link to="/forgotpassword">
                                    <span className=" text-textGrey200">
                                        Forgot Password
                                    </span>
                                </Link>
                            </div>
                        </div>

                        {!loading && (
                            <Button
                                title="Login"
                                className="w-full py-3"
                                onClick={signIn}
                            />
                        )}
                        {loading ? (
                            <Loading className="mx-auto" />
                        ) : (
                            <ButtonClear
                                title="Create Account"
                                className="mx-auto pt-3"
                                onClick={() => {
                                    navigate('/signup')
                                }}
                            />
                        )}
                    </ContentDetail>
                    <p className=" mt-8 text-center text-textGrey100">
                        &copy; 2024 FirmLeads LLC
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SignIn
