import React from 'react'

import Tooltip from '../Tooltip'
import InfoIcon from '../../assets/images/InfoIcon.svg'

interface ProfileAreaInputProps {
    className?: string
    tooltipText?: string
    title?: string
    placeholder?: string
    value: string
    setter: React.Dispatch<React.SetStateAction<string>>
}

const ProfileAreaInput: React.FC<ProfileAreaInputProps> = ({
    className,
    tooltipText,
    title,
    placeholder,
    value,
    setter,
}) => {
    const maxCharacters = 1920
    const handleInputChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setter(event.target.value)
    }

    return (
        <div className="w-full">
            {title && (
                <div className="flex ">
                    <span className="base16 mb-2 font-semibold text-Navy">
                        {title}
                    </span>
                    {tooltipText && (
                        <Tooltip text={tooltipText}>
                            <img
                                src={InfoIcon}
                                className="ml-2"
                                alt="info icon"
                            />
                        </Tooltip>
                    )}
                </div>
            )}
            <textarea
                value={value}
                rows={8}
                placeholder={placeholder ?? title ?? ''}
                onChange={handleInputChange}
                className={
                    className +
                    ' base16 w-full rounded-[12px] border-[1px] border-borderGrey px-3 py-[14px] focus:border-[2px] focus:border-textGrey100 focus:outline-none'
                }
            />
            <p className="font-semibold">
                {maxCharacters - value.length} characters remaining.
            </p>
        </div>
    )
}

export default ProfileAreaInput
